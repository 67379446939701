import { Component } from 'react';
import { AxiosResponse, AxiosError } from "axios";

import '../../styles/Organization.css';
import { Modal } from "react-bootstrap";

import DefaultImage from '../../images/image81.jpg';
import { NavLink } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import ShowMember from '../popup/ShowMember';
import Switch from "react-switch"
// Utils
import { TournamentStatus, VerificationStatus } from '../../utils/enums.utils';
import { profile } from '../../utils/localstorage.utils';
// Pop-up
import ConfirmationModal from '../popup/ConfirmationModal';
import SingleOwnerLeave from '../popup/SingleOwnerLeave';
import { errorHandler, capitalize, defaultPreviewImage, getAdminData, updateLoader } from '../../utils/common.utils';
import OrganizationApis from '../../helper/organization.api';
// Icons
import liveIc from "../../images/icon/live-ic.png";
import draftIc from "../../images/icon/draft-ic.png";
import pastIc from "../../images/icon/past-ic.png";
import ratingIc from "../../images/icon/rating-ic.png";
import configIc from "../../images/icon/config-ic.png";
import OwnerIcon from '../../images/icon/owner-ic.png'
import AdminIcon from '../../images/icon/admin-ic.png'
import { AiOutlineSetting, AiFillMinusCircle, AiFillPlusCircle, AiFillCamera } from "react-icons/ai";
import { IoIosCloseCircleOutline, IoMdCopy } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BsArrowLeftShort } from "react-icons/bs";
import { MdVerified } from "react-icons/md";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaRegEdit } from 'react-icons/fa';
import removeIc from '../../images/icon/remove-ic.png';
import verifyIcon from '../../images/icon/verify-ic.png';
import verify from '../../images/icon/Verify.png';
import deactivateIcon from '../../images/icon/deactivate-ic.png';
import leaveIcon from '../../images/icon/leave-ic.png';
import deleteIcon from '../../images/icon/delete-ic.png';
import instructionIcon from '../../images/icon/instruction-ic.png';
import discord from '../../images/games/discord.png';
import instagram from '../../images/games/instagram.png';
import loader from '../../images/common/Loader.png';
import {
    FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, LineShareButton,
    FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LineIcon
} from "react-share";
import ImagePreview from '../common/ImagePreview';
import DefaultImageLoader from '../../images/img_loader.gif';
import CommonApis from '../../helper/common.api';
import bioIcon from '../../images/icon/bio-icon.png';
import { BiPlusMedical } from 'react-icons/bi';
import { removeOwnerOrAdminNotification } from '../../utils/notification.utils';
import Image from '../common/Image';
import ClanOrgDenStats from '../common/ClanDenStats';
import OrgDenStats from '../common/OrgDenStats';
import { DEN } from '../../utils/Strings.utils';

class OrganizationView extends Component {
    organizationApi = new OrganizationApis()
    commonApi = new CommonApis()
    orgData
    constructor() {
        super();
        this.state = {
            openConfirm: false,
            organizationView: true,
            openBioEdit: false,
            openClanInvite: false,
            selectInvite: true,
            openSingleOwner: false,
            orgData: {},
            description: '',
            orgId: '',
            selectedRole: '',
            invitationLink: null,
            openShowMember: false,
            members: {},
            removeEmployeeId: null,
            removeEmployeeType: '',
            currentUserType: '',
            currentUserId: profile()?.id,
            microWebsiteLink: null,
            bioLimit: 100,
            previewImageDetails: {
                display: false,
                src: defaultPreviewImage
            },
            showOrgDenStats: false,
            denProfile: {}
        };
        this.orgData = {};
        this.adminData = getAdminData();
    }
    componentDidMount() {
        this.props.showOrHideNav(true);
        this.setState({ orgId: this.props.match.params.id })
        this.getData(this.props.match.params.id)
    }

    /*Go to copy invite link */
    linkSectionContinue = () => {
        const baseUrl = window.location.origin;
        let invitationLink;
        invitationLink = `${baseUrl}/?joinOrgId=${this.state.orgId}&role=${this.state.selectedRole}`;
        this.setState({ selectInvite: !this.state.selectInvite, invitationLink: invitationLink })
    }
    copyLinkToClipBoard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(this.state.invitationLink);
        toast.success("Invitation link has been copied to the clipboard.");
    }
    /*Update Org Function */
    updateOrg = (updateMsg, type?) => {
        const {denProfile} = this.state;
        updateLoader(true);
        this.orgData.members = this.state.members;
        this.orgData.members.creator = this.state?.members?.creator?.id;
        this.orgData.members.owner = this.state?.members?.owner?.map(el => { return el?.id })
        if (this.state?.members?.admin) {
            this.orgData.members.admin = this.state?.members?.admin?.map(el => { return el?.id })
        }
        this.orgData.denProfile = denProfile;
        
        this.organizationApi.updateOrganization(this.orgId, this.orgData).then(
            (res: AxiosResponse) => {
                var data = res.data['result'];
                this.orgData = { ...data, ...this.orgData };
                this.setState({ 
                    orgData: data, 
                    openBioEdit: false, 
                    openConfirm: false, 
                    //organizationView: true, 
                    //denProfile: data.denProfile ? {...data.denProfile, ...denProfile} : denProfile, 
                    //showOrgDenStats: data.denProfile?.isActive
                })
                this.getData(this.orgId);
                updateLoader(false);
                toast.success(updateMsg)
                if (type === 60) {
                    this.getData(this.orgId);
                    //this.history.push(`/organization/${this.orgId}`);
                    return false
                }
                if (type === 300) {
                    this.props.history.push("/organizations");
                }
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    deleteOrgMsg = () => {
        return (
            <p className="fs-14 fw-600 text-white-50"> 
                <span className="error-msg">Upon deletion of the Organization, all the history of the  tounament will get deleted every member will be removed from the organization. <br /><br/>
                The organization will no longer exist, so no Espotz tournaments can be registered by using this organization anymore. </span> <br/><br/>
                Are you sure you want to temporarily <span className="error-msg">delete</span> the organization?
            </p>
        )
    }
    confirmed = (key) => {
        if (key === 'deleteOrg') {
            this.deleteOrg()
        } else if (key === 'deactivateOrg') {
            const data = this.state.orgData
            data.isOrganizationActive = !data.isOrganizationActive;
            this.orgData = data;
            this.setState({ orgData: data })
            this.updateOrg(data.isOrganizationActive ? 'Organization activated' : 'Organization deactivated')
        } else if (key === 'leaveOrg') {
            if (this.state.currentUserType === 'admin') {
                const data = this.state.orgData
                let index = this.state.orgData?.members?.admin.map(el => { return el.id }).indexOf(this.state.currentUserId)
                data?.members?.admin.splice(index, 1);
                this.setState({ orgData: data })
            } else {
                const data = this.state.orgData
                let index = this.state.orgData?.members?.owner.map(el => { return el.id }).indexOf(this.state.currentUserId)
                data?.members?.owner.splice(index, 1);
                this.setState({ orgData: data })
            }
            this.updateOrg('Organization left successfully', 300)
        } else if (key === 'removeAdmin' || key === 'removeOwner') {
            this.removeOwner()
        }
    }
    descChange = (e) => {
        this.setState({
            description: e.target.value
        })
    }
    onRoleChangeHandler = (e) => {
        this.setState({
            selectedRole: e.target.name,
        });
    };
    updateDesc = () => {
        const data = this.state.orgData
        data.bio = this.state.description
        this.orgData.bio = this.state.description;
        this.setState({ orgData: data })
        this.updateOrg('Description updated')
    }
    toggleModal = (key) => {
        if(this.checkStatus()){
            this.setState({ [key]: !this.state[key] });
        }
    }
    onClickOpenConfirm = (key?, msg?) => {
        this.key = key;
        if(this.checkStatus()){
            const data = {
                msg: msg ? msg : '',
                key: key ? key : ''
            }
            if (key === 'leaveOrg' && this.state.orgData?.members?.owner?.length === 1) {
                this.setState({
                    openSingleOwner: !this.state.openSingleOwner,
                });
            } else if (key === 'removeOwner' && this.state.orgData?.members?.owner?.length === 1) {
                this.setState({
                    openSingleOwner: !this.state.openSingleOwner,
                });
            } else {
                this.setState({
                    openConfirm: !this.state.openConfirm,
                    confirm: data
                });
            }
        }
    }
    /*After Single Owner Confirmation */
    singleOwnerConfirm = (val, data?) => {
        if (val === 'delete') {
            this.deleteOrg()
        } else if (val === 'deactivate') {
            const tempData = this.state.orgData
            tempData.isOrganizationActive = false
            this.setState({ orgData: tempData })
            this.updateOrg('organization Deactivated')
        } else if (val === 'assign') {
            const tempData = this.state.orgData
            tempData.members = data
            this.setState({ orgData: tempData })
            this.updateOrg('Owner assigned and organization left', 300)
        }
        this.setState({ openSingleOwner: false })
    }
    
    /*Remove Members  */
    removeOwner = () => {
        const data = this.state.orgData
        if (this.state.removeEmployeeType === 'Owner') {
            const index = data?.members?.owner.map(el => { return el.id }).indexOf(this.state.removeEmployeeId)
            data.members?.owner.splice(index, 1);
            this.setState({ orgData: data });
            removeOwnerOrAdminNotification('organization', data, this.state.removeEmployeeId, 'Owner');
            this.updateOrg('Owner Removed Successfully')
        } else if (this.state.removeEmployeeType === 'Admin') {
            const index = data?.members?.admin.map(el => { return el.id }).indexOf(this.state.removeEmployeeId)
            data.members?.admin.splice(index, 1);
            this.setState({ orgData: data });
            removeOwnerOrAdminNotification('organization', data, this.state.removeEmployeeId, 'Admin');
            this.updateOrg('Admin Removed Successfully')
        }
        this.setState({
            openConfirm: false,
        });
    }

    getData(id) {
        const {denProfile} = this.state;
        const data = { _id: id }
        updateLoader(true);
        this.organizationApi.getOrganizations(data).then(
            (res: AxiosResponse) => {
                if (res.data['result']?.length === 0) {
                    this.props.history.push("/organizations");
                }else{
                    var data = res.data['result'][0];
                    this.orgId = res.data['result'][0]?._id;
                    this.orgData = { ...data, ...this.orgData };
                    this.setState({
                        orgData: data,
                        description: res.data['result'][0].bio,
                        denProfile: data.denProfile ? {...data.denProfile, ...denProfile} : denProfile,
                        showOrgDenStats: this.orgData?.denProfile ? this.orgData?.denProfile?.isActive : false,
                        members: data.members
                    })
                    if (data.members?.owner.map(el => { return el.id }).includes(this.state.currentUserId)) {
                        this.setState({ currentUserType: 'owner', selectedRole: 'owner' })
                    } else {
                        this.setState({ currentUserType: 'admin', selectedRole: 'admin' })
                    }
                }
                if(data.den){
                    this.updateDenProfile();
                }
                updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    deleteOrg = () => {
        let id = this.props.match.params.id
        updateLoader(true);
        this.organizationApi.deleteOrganization(id).then(
            (res: AxiosResponse) => {
                toast.success('Organization deleted successfully')
                updateLoader(false);
                this.props.history.push("/organizations");
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }
    requestVarification = () => {
        const data = this.state.orgData
        data.verificationStatus = VerificationStatus.VERIFICATION_REQUESTED
        this.orgData.verificationStatus = VerificationStatus.VERIFICATION_REQUESTED;
        this.setState({ orgData: data });
        this.updateOrg('Verification request sent');
    }
    /*ToTo Rander Varification Request Status */
    renderVarification(val) {
        switch (val) {
            case VerificationStatus.VERIFIED:
                return (<><button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52"><img src={verifyIcon} style={{ height: '20px' }} alt='' /></button><p className='fs-12 fw-bold text-white mt-1'>Verified</p></>
                );
            case VerificationStatus.VERIFICATION_REQUESTED:
                return (<><button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52"><img src={verifyIcon} style={{ height: '20px' }} alt='' /> </button>
                    <p className='fs-12 fw-bold text-white mt-1'><span className='d-block'>Request</span> Verification</p></>
                );
            case VerificationStatus.NOT_VERIFIED:
                return (<><button disabled={!this.state?.orgData?.isOrganizationActive} className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52" onClick={this.requestVarification}><img src={verify} style={{ height: '20px' }} alt='' /></button><p className='fs-12 fw-bold text-white mt-1'>Verify</p></>
                );
            default:
                return null;
        }
    }
    /*deactivate Confirmation Msg */
    deactivateMsg = () => {
        return (
            this.state.orgData.isOrganizationActive ?
                <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to temporarily <span className="error-msg">deactivate</span> the Organization?</p>
                :
                <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to activate this Organization</p>
        )
    }
    /*Leave Confirmation Msg */
    leaveOrgMsg = () => {
        return (
            <p className="fs-14 fw-600 text-center error-msg">Are you sure you want to leave the current Organization?</p>
        )
    }
    /*Remove Confirmation Msg */
    removeOwnerMsg = (position, name) => {
        return (
            <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to remove {name} from the Organization {position} position?</p>
        )
    }
    /*To Open Show Member Popup */
    onClickOpenShowMember = (members?, title?) => {
        const data = {
            members: members ? members : '',
            title: title ? title : ''
        }
        this.setState({
            openShowMember: !this.state.openShowMember,
            members: data,
        })
    }

    copyToClipBoard = () => {
        navigator.clipboard.writeText(this.state?.orgData?.microWebsiteLink);
        toast.success("Micro Website link has been copied to the clipboard.");
    }

    previewImage = (src, param) => {
        this.setState({
            previewImageDetails: {
                display: param,
                src: (param ? src : defaultPreviewImage)
            }
        });
    }

    addImg = (event, type) => {
        let message;
        if (type === 'logo') {
            message = 'Organization logo updated successfully'
        } else {
            message = 'Organization cover image updated successfully'
        }
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            this.orgData[type].url = URL.createObjectURL(img)
            //const { orgData } = this.state
            //orgData[type].url = URL.createObjectURL(img)
            this.setState({ orgData: this.orgData });
            const formData = new FormData();
            formData.append('file', event.target.files[0])
            updateLoader(true);
            this.commonApi.uploadSingleImg(formData).then(
                (response: AxiosResponse) => {
                    //const { orgData } = this.state
                    this.orgData[type].url = response.data.result.url;
                    this.setState({ orgData: this.orgData })
                    this.updateOrg(message, 60)
                    updateLoader(false);
                }).catch(
                    (err: AxiosError) => {
                        updateLoader(false);
                        errorHandler(err, this.props.logout);
                    });
        }
    };

    triggerInputFile = () => {
        if(this.checkStatus()){
            this.fileInput.click();
        }
    }

    triggerInputFile2 = () => {
        if(this.checkStatus()){
            this.fileInput2.click();
        }
    }

    editMicrowebDetails = () => {
        if(this.checkStatus()){
            this.props.history.push(`/organization/micro-website/configuration/config-${this.props.match.params.id}`);
        }
    }

    inProgress = () => {
        toast.warning('Work In Progress..')
    }

    updateDenStatus = () => {
        if(this.checkStatus()){
            const denProfile = this.state.denProfile;
            const publicStatus = {
                "matchPlayed": this.state?.denProfile.publicStatus?.matchPlayed ? this.state.denProfile.publicStatus.matchPlayed : true,
                "matchWin": this.state?.denProfile.publicStatus?.matchWin ? this.state.denProfile.publicStatus.matchWin : true,
                "spotlightCollection": this.state?.denProfile.publicStatus?.spotlightCollection ? this.state?.denProfile.publicStatus.spotlightCollection : true,
                "mvpCount": this.state?.denProfile.publicStatus?.mvpCount ? this.state?.denProfile.publicStatus.mvpCount : true,
                "gameStats": this.state?.denProfile.publicStatus?.gameStats ? this.state?.denProfile.publicStatus.gameStats : true,
                "bettingStatistics": this.state?.denProfile.publicStatus?.bettingStatistics ? this.state?.denProfile.publicStatus.bettingStatistics : true,
                "tournamentCount": this.state?.denProfile.publicStatus?.tournamentCount ? this.state?.denProfile.publicStatus.tournamentCount : true,
            };
            denProfile['isActive'] = !this.state.showOrgDenStats;
            denProfile['members'] = this.state.orgData?.members;
            denProfile['logo'] = this.state.orgData?.logo;
            denProfile['coverImage'] = this.state.orgData?.coverImage;
            denProfile['type'] = DEN.SEARCH.TYPES.ORG;
            denProfile['name'] = this.state.orgData?.name;
            denProfile['bio'] = this.state.orgData?.bio;
            denProfile['clanOrOrgId'] = this.state.orgData?._id;
            denProfile['publicStatus'] = publicStatus;
            denProfile['isPublic'] = this.state.denProfile?.isPublic ? denProfile.isPublic : false;
            denProfile['socialMedia'] = this.state.denProfile?.socialMedia ? denProfile.socialMedia : [];
            denProfile['sponsors'] = this.state.denProfile?.sponsors ? denProfile.sponsors : [];
            
            this.setState({
                showOrgDenStats : !this.state.showOrgDenStats,
                denProfile : denProfile
            });
            let msg;
            if(this.state.denProfile.isActive){
                msg='Organization den profile activated successfully';
            }else{
                msg='Organization den profile deactivated successfully';
            }
            this.updateDenProfile(msg);
        }
    }

    checkStatus = () => { 
        if(this.key==='deactivateOrg'){
            return true;
        }
        if(profile()?.block){
          toast.warning('You cannot perform this action as you are blocked by Espotz Admin');
          return false;
        }
        if(this.state.orgData?.block){
            toast.warning('The organization has been blocked by Espotz Admin');
            return false;
        }
        if (!this.state.orgData?.isOrganizationActive) {
          toast.warning('Please activate the organization to perform this action');
          return false;
        }
        if(!(this.state.currentUserType ==='owner' || this.state.currentUserType === 'admin')){
          toast.warning('Only organization owner/admin can perform this action');
          return false;
        }
        return true;
    }

    updateDenProfile = (updateMsg) => {
        const {organizationView} = this.state;
        const data = { 
            denData : this.state.denProfile
        }
        updateLoader(true);
        this.organizationApi.updateOrganization(this.orgId, data).then(
            (res: AxiosResponse) => {
                var data = res.data['result']
                this.orgData = { ...data, ...this.orgData };
                this.setState({
                    openBioEdit: false, 
                    openConfirm: false, 
                    organizationView: organizationView, 
                    denProfile: data.denProfile, 
                    showOrgDenStats: data.denProfile?.isActive
                })
                updateLoader(false);
                updateMsg && toast.success(updateMsg) 
            }
        ).catch(
            (err: AxiosError) => {
                updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    setDenData = (data, msg) => {
        this.setState({denProfile: data});
        this.updateDenProfile(msg);
    }

    redirectToDen = () => { 
        if(this.checkStatus()){
            if(!this.state.denProfile){
                toast.warning('This organization does not have a den Profile yet.');
                return false;
            }
            this.props.history.push(`/den/${this.state.denProfile?._id}`);
        }
    }

    checkUserStatus = (e) => {
        if(!this.checkStatus()){
            e.preventDefault()
        }
    }

    render() {
        const { openClanInvite, openBioEdit } = this.state
        var inviteText = `Join Espotz Organization named ${this.state.orgData?.name} as ${this.state.selectedRole} using this link :`;
        if(this.orgData){
        return (
            <>
                <section className="body-section organizationView-page">
                    <div className="clanView-banner">
                        <img alt='' className="clanView-background" src={this.state.orgData?.coverImage?.url ? this.state?.orgData?.coverImage?.url : DefaultImage} width="50" height="50" />
                        <img alt='' className="clanView-fronImage"
                            src={this.state.orgData?.coverImage?.url ? this.state?.orgData?.coverImage?.url : DefaultImageLoader}
                            onClick={() => this.previewImage((this.state.orgData?.coverImage?.url ? this.state?.orgData?.coverImage?.url : DefaultImageLoader), true)}
                            width="50" height="50" />
                        {(this.state.organizationView) ?
                            null :
                            <div className='cover-cam-container bg-white position-absolute btn-size-25 rounded-circle d-flex align-items-center justify-content-center' style={{ top: '80px', right: '10px' }}>
                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'coverImage')} ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="profile-picker" />
                                <AiFillCamera className="cursor-pointer text-dark" id="upload_file" onClick={this.triggerInputFile2} />
                            </div>
                        }
                    </div>

                    {this.state.organizationView ?

                        <div className="container ClanView-section ps-4 pe-4">
                            <NavLink to="/organizations">
                                <IoArrowBackCircleOutline className="back-btn" />
                            </NavLink>

                            <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-100px' }}>
                                <div className="col-12">
                                    <div className="clan-view-logo text-center">
                                        <img alt='' src={this.state.orgData?.logo?.url ? this.state?.orgData?.logo?.url : DefaultImageLoader} className="organization-logo rounded-10"
                                            onClick={() => this.previewImage((this.state.orgData?.logo?.url ? this.state?.orgData?.logo?.url : DefaultImageLoader), true)}
                                        />
                                        <h6 className="fs-18 fs-lg-24 fw-600 text-white mt-1 text-truncate">
                                            {this.state?.orgData?.name}
                                            {
                                                this.state?.orgData?.verificationStatus === VerificationStatus.VERIFIED && <MdVerified className="user-verified ms-2" />
                                            }

                                        </h6>
                                    </div>

                                    <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 position-absolute" style={{ right: '0px', marginTop: '-60px' }} onClick={() => { this.setState({ organizationView: !this.state.organizationView }) }}>
                                        <AiOutlineSetting className="" style={{ width: '20px', height: '20px' }} />
                                    </button>
                                </div>
                            </div>

                            <span className='d-flex align-items-center mb-1'>
                                <img src={bioIcon} className="ms-1 mb-1" alt='' style={{ width: '20px' }} />
                                <p className='fs-14 fw-600 ms-2 mb-0 text-white'>Bio</p>
                            </span>
                            <p className="fs-14 fs-lg-18 fw-normal text-white-50" style={{ textAlign: 'justify', overflowWrap: 'break-word' }}>{this.state?.orgData?.bio?.substring(0, this.state.bioLimit)} {(this.state?.orgData?.bio?.length > 100) ? (this.state.bioLimit === 100 ? <a className="fs-14 fw-normal" onClick={() => { this.setState({ bioLimit: 500 }) }}>Show More</a> : <a className="fs-14 fw-normal" onClick={() => { this.setState({ bioLimit: 100 }) }}>Show Less</a>) : null}</p>

                            {/* <div className="row my-3 mt-3">
                                <div className="col-6">
                                    <h6 className="clanView-heading">Members</h6>
                                </div>
                                <div className="col-6">
                                    <button disabled={!this.state?.orgData?.isOrganizationActive} className="btn clan-btn invite-btn float-end" style={{ marginTop: '-5px' }} onClick={() => { this.toggleModal('openClanInvite'); }}>invite</button>
                                </div>
                            </div> */}

                            <div className="row my-3">
                                <div className="col-lg-2 col-md-4 col-6">
                                    <h6 className='fs-16 fw-600 text-white member-type'><img src={OwnerIcon} className="me-1" alt="owner icon" style={{ width: '20px', height: '25px' }} /> Owners</h6>
                                    {
                                        (this.state?.orgData?.members?.owner && this.state?.orgData?.members?.owner?.length) ?
                                            <div className='d-flex justify-content-between'>
                                                <div className="avatars ms-3 cursor-pointer" onClick={() => { this.onClickOpenShowMember(this.state?.orgData?.members?.owner, 'Organization Owner') }}>
                                                    {
                                                        (this.state?.orgData?.members?.owner && this.state?.orgData?.members?.owner?.length) ?
                                                            this.state?.orgData?.members?.owner.map((el, i) => {
                                                                if (i < 4) {
                                                                    return (
                                                                        <span className="avatar" key={i}>
                                                                            {/* <img className='btn-size-32 border border-1' alt='' src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} width="50" height="50" /> */}
                                                                            <Image src={el?.profilePicture?.url} param={el?.inGameName} className='btn-size-32 border border-1' />
                                                                        </span>
                                                                    )
                                                                }
                                                                return false
                                                            }) : ''
                                                    }
                                                    {
                                                        (this.state?.orgData?.members?.owner && this.state?.orgData?.members?.owner?.length > 4) ?
                                                            <span className="avatar d-inline-flex">
                                                                <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center'>+{this.state?.orgData?.members?.owner?.length - 4}</p>
                                                            </span>
                                                            : null
                                                    }
                                                </div>
                                                <div>
                                                    <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggleModal('openClanInvite'); }}>
                                                        <BiPlusMedical className='text-black' />
                                                    </button>
                                                    {/* <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 d-flex align-items-center fs-30" data-dismiss="modal" aria-label="Close" onClick={() => { this.toggleModal('openClanInvite'); }}>
                                                        <AiFillPlusCircle className="" />
                                                    </button> */}
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p className="black-flat-btn p-1 ms-4 w-75 text-center mb-0 member-type-btn rounded-10" onClick={this.onClickOpenClanInvite}>
                                                    <AiFillPlusCircle className='' />
                                                </p>
                                            </div>
                                    }
                                </div>

                                <div className="col-lg-2 col-md-4 col-6">
                                    <h6 className='fs-16 fw-600 text-white member-type'><img src={AdminIcon} className="me-1" alt="admin icon" style={{ width: '20px', height: '25px' }} /> Admins</h6>
                                    {
                                        (
                                            this.state?.orgData?.members?.admin && this.state?.orgData?.members?.admin?.length) ?
                                            <div className='d-flex justify-content-between'>
                                                <div className="avatars ms-3 cursor-pointer" onClick={() => { this.onClickOpenShowMember(this.state?.orgData?.members?.admin, 'Organization Admin') }}>
                                                    {
                                                        (this.state?.orgData?.members?.admin && this.state?.orgData?.members?.admin?.length) ?
                                                            this.state?.orgData?.members?.admin.map((el, i) => {
                                                                if (i < 4) {
                                                                    return (
                                                                        <span className="avatar" key={i}>
                                                                            {/* <img className='btn-size-32 border border-1' src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile-logo" width="50" height="50" /> */}
                                                                            <Image src={el?.profilePicture?.url} param={el?.inGameName} className='btn-size-32 border border-1' />
                                                                        </span>
                                                                    )
                                                                }
                                                                return false
                                                            }) : ''
                                                    }
                                                    {
                                                        (this.state?.orgData?.members?.admin && this.state?.orgData?.members?.admin?.length > 4) ?
                                                            <span className="avatar d-inline-flex">
                                                                <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center'>+{this.state?.orgData?.members?.admin?.length - 4}</p>
                                                            </span>
                                                            : null
                                                    }
                                                </div>
                                                <div>
                                                    <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={() => { this.toggleModal('openClanInvite'); }}>
                                                        <BiPlusMedical className='text-black' />
                                                    </button>
                                                    {/* <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 d-flex align-items-center fs-30" data-dismiss="modal" aria-label="Close" onClick={() => { this.toggleModal('openClanInvite'); }}>
                                                        <AiFillPlusCircle className="" />
                                                    </button> */}
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p disabled={!this.orgData?.isActive} className="black-flat-btn p-1 ms-4 w-75 text-center mb-0 member-type-btn rounded-10" onClick={() => { this.toggleModal('openClanInvite'); }}>
                                                    <AiFillPlusCircle className='fs-20 my-1' />
                                                </p>
                                            </div>
                                    }
                                </div>
                                <div className='row my-3 d-flex justify-content-center'>
                                    <div className="col-12 col-md-4">
                                        <NavLink to={{
                                            pathname: `/tournaments/my-tournaments`,
                                            state: {
                                                fromOrg: true,
                                                organizationId: this.state.orgData?._id
                                            }
                                            
                                        }} onClick={this.checkUserStatus}>
                                            <button className='pink-skyblue-gradient-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0'>Tournaments</button>
                                        </NavLink>
                                    </div>
                                    <div className={`${(this.state.denProfile && this.state.denProfile.isActive) ? 'col-12 col-md-4' : 'd-none'}`}>
                                        <button className='pink-skyblue-gradient-btn flat-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0' onClick={this.redirectToDen}>Den</button>
                                    </div>
                                    <NavLink to={`/organization/wallet/${this.state.orgData._id}`} className="col-12 col-md-4">
                                        <button className='pink-skyblue-gradient-btn flat-btn wallet-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0'>Wallet</button>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="row mt-3 justify-content-center">
                                {/* Create Tournament */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3 mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?

                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournament/create`,
                                            state: {
                                                contact: this.state.orgData?.socialMediaDetails,
                                                id: this.state.orgData._id,
                                                to: 'Create'
                                            }
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block create-tournament-card magenta-blue-gradient-btn text-center w-100 fw-bold p-4 m-auto mb-2 text-white border-0`}>
                                                <div className="d-inline-flex">
                                                    <AiFillPlusCircle className="btn-size-32" />
                                                </div>
                                                <div className="fs-14 fw-600 text-center text-white ms-1">Create Tournament</div>
                                            </div>
                                        </NavLink>

                                        :
                                        <div className={`tournament-block create-tournament-card magenta-blue-gradient-btn d-flex align-items-center justify-content-center w-100 fw-bold p-4 m-auto mb-2 text-white border-0 epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <AiFillPlusCircle className="btn-size-32" />
                                            </div>
                                            <div className="fs-14 fw-600 text-center text-white ms-1">Create Tournament</div>
                                        </div>
                                    }
                                </div>
                                {/* Live Tournaments */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournaments/${TournamentStatus.ONGOING}`
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={liveIc} className="btn-size-25 text-white" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.liveTournamentCount}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Live Tournaments</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={liveIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.liveTournamentCount}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Live Tournaments</div>
                                        </div>
                                    }
                                </div>
                                {/* Draft Tournaments */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournaments/${TournamentStatus.DRAFT}`
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={draftIc} className="btn-size-32" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.draftTournamentCount}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Draft Tournament</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={draftIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.draftTournamentCount}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Draft Tournament</div>
                                        </div>
                                    }
                                </div>
                                {/* Past Tournaments */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournaments/${TournamentStatus.COMPLETED}`
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={pastIc} className="btn-size-25 text-white" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.pastTournamentCount}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Past Tournaments</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={pastIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.pastTournamentCount}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Past Tournaments</div>
                                        </div>
                                    }
                                </div>
                                {/* Upcoming Tournaments */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournaments/${TournamentStatus.UPCOMING}`
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={pastIc} className="btn-size-25 text-white" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.upcomingTournamentCount ? this.state.orgData?.upcomingTournamentCount : 0}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Upcoming Tournaments</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={pastIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.upcomingTournamentCount ? this.state.orgData?.upcomingTournamentCount : 0}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Upcoming Tournaments</div>
                                        </div>
                                    }
                                </div>
                                {/* Cancelled Tournaments */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{
                                            pathname: `/organization/${this.state.orgData._id}/tournaments/${TournamentStatus.CANCELLED}`
                                        }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={pastIc} className="btn-size-25 text-white" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.cancelledTournamentCount ? this.state.orgData?.cancelledTournamentCount : 0}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Cancelled Tournaments</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block mulberry-purple-card w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={pastIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.cancelledTournamentCount ? this.state.orgData?.cancelledTournamentCount : 0}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Cancelled Tournaments</div>
                                        </div>
                                    }
                                </div>
                                {/* Ratings and Reviews */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3  mb-md-3 mb-2">
                                    {this.state.orgData?.isOrganizationActive ?
                                        <NavLink to={{ pathname: `/organization/${this.state.orgData._id}/tournaments-ratings` }} onClick={this.checkUserStatus}>
                                            <div className={`tournament-block orange-gradient-btn w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center`}>
                                                <div className="d-inline-flex">
                                                    <img alt='' src={ratingIc} className="btn-size-32" />
                                                    <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.organizationRating ? this.state.orgData?.organizationRating?.toFixed(2) : 0}</span>
                                                </div>
                                                <div className="fs-14 fw-700">Rating Reviews</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <div className={`tournament-block orange-gradient-btn w-100 fw-bold p-4 m-auto mb-2 text-white border-0 text-center epz-clan-deactivate`} onClick={this.checkStatus}>
                                            <div className="d-inline-flex">
                                                <img alt='' src={ratingIc} className="btn-size-32" />
                                                <span className="fs-20 fw-600 text-white ms-1 mt-1">: {this.state.orgData?.organizationRating ? this.state.orgData?.organizationRating : 0}</span>
                                            </div>
                                            <div className="fs-14 fw-700">Rating Reviews</div>
                                        </div>
                                    }
                                </div>
                                {/* Configure Micro Website */}
                                <div className="col-lg-2 col-md-3 col-6 mb-lg-3 mb-md-3 mb-2">
                                    <div className={`tournament-block green-gradient-btn w-100 fw-bold px-3 py-4 m-auto mb-2 text-white border-0 text-center ${this.state.orgData?.isOrganizationActive ? '' : 'epz-clan-deactivate'}`}
                                        onClick={this.editMicrowebDetails}>
                                        <div className="d-inline-flex">
                                            <img alt='' src={configIc} className="btn-size-32" />
                                        </div>
                                        <div className="fs-14 fw-700">Configure
                                            Micro-Website</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12 col-lg-8 offset-lg-2">
                                    <div className="cello-gradient-btn text-center py-3">
                                        <h4 className="fs-14 fw-600 text-white">Micro Website URL </h4>

                                        {/* <a className="webUrlLink" href={`${this.state?.orgData?.microWebsiteLink}`} target="_blank">{this.state?.orgData?.microWebsiteLink}</a> */}

                                        <div className="d-flex align-items-center border-1 rounded-10 mb-3 mx-auto height-45" style={{ width: '90%', background: 'rgba(47, 48, 83, 0.5)', border: '1px solid #2f3053' }}>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="fw-600 fs-14 text-center text-info border-0 flex-grow-1 bg-transparent ps-3 height-45"
                                                value={this.state?.orgData?.microWebsiteLink}
                                                href={`${this.state?.orgData?.microWebsiteLink}`}
                                                target="_blank"
                                            />
                                            <MdOutlineContentCopy
                                                className="mb-2 btn-size-20 fs-16 m-2"
                                                style={{ marginTop: '10px', color: '#0BB5FE' }}
                                                onClick={this.copyToClipBoard}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-center my-2">
                                            <div className="d-flex align-items-center w-50 mb-0">
                                                <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                                                <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
                                                <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <WhatsappShareButton className="px-1" url={this.state?.orgData?.microWebsiteLink}
                                                title={`microWebsiteLink :`}>
                                                <WhatsappIcon size={"2rem"} round={true} />
                                            </WhatsappShareButton>

                                            <FacebookShareButton className="px-1" url={this.state?.orgData?.microWebsiteLink} title={`microWebsiteLink :`}>
                                                <FacebookIcon size={"2rem"} round={true} />
                                            </FacebookShareButton>

                                            <TelegramShareButton className="px-1" url={this.state?.orgData?.microWebsiteLink} title={`microWebsiteLink :`}>
                                                <TelegramIcon size={"2rem"} round={true} />
                                            </TelegramShareButton>

                                            <TwitterShareButton className="px-1" url={this.state?.orgData?.microWebsiteLink} title={`microWebsiteLink:`}>
                                                <TwitterIcon size={"2rem"} round={true} />
                                            </TwitterShareButton>
                                            {/*----------------Removed for now for demo ---------------------*/}
                                            {/* <img src={discord} className="px-1" alt='' style={{ height: '28px' }} onClick={this.inProgress} />
                                            <img src={instagram} className="px-1" alt='' style={{ height: '28px' }} onClick={this.inProgress} /> */}
                                            <img src={loader} className="px-1" alt='' style={{ height: '28px' }} onClick={this.inProgress} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="container setting-section ps-4 pe-4">
                            <IoArrowBackCircleOutline className="back-btn" onClick={() => { this.setState({ organizationView: !this.state.organizationView }) }} />

                            <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-100px' }}>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex clan-view-logo p-0">
                                        <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'logo')} ref={fileInput => this.fileInput = fileInput} name="" className="profile-picker" />
                                        <img src={this.state.orgData?.logo?.url ? this.state?.orgData?.logo?.url : DefaultImage} className="organization-logo rounded-10" alt='' />
                                        <div className='logo-cam cover-cam-container bg-white position-absolute btn-size-25 rounded-circle d-flex align-items-center justify-content-center' style={{ marginLeft: '80px', marginTop: '80px' }}>
                                            <AiFillCamera className="cursor-pointer text-dark" id="upload_file" onClick={this.triggerInputFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h6 className="fs-18 fs-lg-24 fw-600 text-white mt-2 text-center text-truncate">
                                {this.state?.orgData?.name}
                            </h6>

                            <div className='d-flex justify-content-between'>
                                <span className='d-flex align-items-center'>
                                    <img src={bioIcon} className="ms-1 mb-1" alt='' style={{ width: '20px' }} />
                                    <p className='fs-14 fw-600 ms-2 mb-0 text-white'>Bio</p>
                                </span>
                                {/* {
                                    (this.state.isAdmin || this.state.isOwner) && */}
                                <FaRegEdit disabled={!this.state?.orgData?.isOrganizationActive} className="fs-20 float-end" onClick={() => { this.toggleModal('openBioEdit'); }} />
                                {/* } */}
                            </div>

                            <p className="martinique-card fs-12 fs-lg-16 fw-600 p-3 mt-2 mb-3" style={{ textAlign: 'justify', overflowWrap: 'break-word' }}>{this.state.orgData?.bio}</p>
                            {/* <button disabled={!this.state?.orgData?.isOrganizationActive} className="btn clan-btn clanViewSetting-btn ms-2" onClick={() => { this.toggleModal('openBioEdit'); }}>Edit</button>
                            <p className="bio-ContentSetting mt-3">{this.state.description}</p> */}

                            <div className="row mb-md-5">


                                <div className="col-12" >
                                    <p className="fs-16 fw-600 text-white text-center mb-1"> <img src={OwnerIcon} alt='clan owner' style={{ width: '20px', height: '25px' }} />Owners</p>
                                    <div >
                                        <div className="row text-center" style={{ justifyContent: 'center' }}>
                                            {
                                                this.state.orgData?.members?.owner ?
                                                    this.state.orgData?.members?.owner.map((el, i) => {
                                                        return (
                                                            <div className="col-12 col-md-6 mt-md-3 col-lg-4 mt-2 mt-lg-4" key={i} >
                                                                <div className="mulberry-purple-card d-flex align-items-center py-1">
                                                                    <div className="col-3">
                                                                        <img className="rounded-circle btn-size-32 border border-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" />
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <div className="fs-16 fw-500 text-white text-start text-truncate">{el?.username}</div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        {
                                                                            (this.state.currentUserType === 'owner') &&
                                                                            <button disabled={!this.state?.orgData?.isOrganizationActive} type="button" className="border border-0 d-flex align-items-center bg-transparent" onClick={() => { this.onClickOpenConfirm('removeOwner', this.removeOwnerMsg('Owner', el?.username)); this.setState({ removeEmployeeId: el?.id, removeEmployeeType: 'Owner' }) }}>
                                                                                <img src={removeIc} className='btn-size-40' alt=''/>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <p className='fs-12 fw-500 text-white my-1'>No Owners present</p>
                                            }
                                            {/* {
                                                    (this.state.currentUserType === 'owner') &&
                                                    <div className="owner-body mt-1">
                                                        <button disabled={!this.state?.orgData?.isOrganizationActive} type="button" className="btn mx-auto" onClick={() => { this.toggleModal('openClanInvite'); this.setState({ selectedRole: 'owner', selectInvite: true }) }}>
                                                            <AiFillPlusCircle className="btn-size-25 text-white" />
                                                        </button>
                                                    </div>
                                                } */}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mt-3" >
                                    <p className="fs-16 fw-600 text-white text-center mb-2"> <img src={AdminIcon} alt='clan owner' style={{ width: '20px', height: '25px' }} />Admins</p>

                                    <div className="row text-center" style={{ justifyContent: 'center' }}>
                                        {
                                            this.state.orgData?.members?.admin ?
                                                this.state.orgData?.members?.admin.map((el, i) => {
                                                    return (
                                                        <div className="col-12 col-md-6 mt-md-3 col-lg-4 mt-2 mt-lg-4" key={i} >
                                                            <div className="mulberry-purple-card d-flex align-items-center py-1">
                                                                <div className="col-3">
                                                                    <img className="rounded-circle btn-size-32 border border-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" />
                                                                </div>
                                                                <div className="col-7">
                                                                    <div className="fs-16 fw-500 text-white text-start text-truncate">{el?.username}</div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <button disabled={!this.state?.orgData?.isOrganizationActive} type="button" className="border border-0 d-flex align-items-center bg-transparent m-auto" onClick={() => { this.onClickOpenConfirm('removeAdmin', this.removeOwnerMsg('Admin', el?.username)); this.setState({ removeEmployeeId: el?.id, removeEmployeeType: 'Admin' }) }}>
                                                                        <img src={removeIc} className='btn-size-40' alt=''/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : <p className='fs-12 fw-500 text-white my-1'>No Admins present</p>
                                        }
                                    </div>

                                </div>
                                {/* Organisation Den Profile */}
                                <div className={`${ (this.state.currentUserType ==='owner' || this.state.currentUserType === 'admin') ? 'd-block' : 'd-none' }`}>
                                    <div className="catalina-blue my-3 height-45 d-flex align-items-center">
                                        <p className="fs-12 fw-600 mb-0 flex-grow-1 ps-3">Den Profile</p>
                                        <Switch checked={this.state.showOrgDenStats} checkedIcon={false} onChange={this.updateDenStatus} uncheckedIcon={false} className="me-2" />
                                    </div>

                                    <div className={`${this.state.showOrgDenStats ? 'd-block' : 'd-none'}`}>
                                        <OrgDenStats denProfile={this.state.denProfile} setDenData={this.setDenData} />
                                    </div>
                                </div>

                                <p className='text-start fs-16 fw-700'>Control Center<span className='mx-2 fw-500'>(Organization)</span></p>

                                <div className='col-12 col-lg-6 offset-lg-3 d-flex justify-content-around my-3 text-center'>
                                    <div>
                                        {this.renderVarification(this.state.orgData?.verificationStatus)}
                                    </div>

                                    <div>
                                        {(this.state.currentUserType === 'owner') &&
                                            <>
                                                <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52" onClick={() => { this.onClickOpenConfirm('deactivateOrg', this.deactivateMsg()) }}>
                                                    <img src={deactivateIcon} style={{ height: '20px' }} alt='' />
                                                </button>
                                                <p className='fs-12 fw-bold text-white mt-1'>{this.state.orgData.isOrganizationActive ? 'Deactivate' : 'Respawn'}</p>
                                            </>}
                                    </div>
                                    <div><>
                                        <button disabled={(this.state.currentUserType === 'owner') && !this.state.orgData.isOrganizationActive} className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52" onClick={() => { this.onClickOpenConfirm('leaveOrg', this.leaveOrgMsg()) }}>
                                            <img src={leaveIcon} style={{ height: '20px' }} alt='' />
                                        </button>
                                        <p className='fs-12 fw-bold text-white mt-1'>Leave</p>
                                    </>
                                    </div>
                                    <div>
                                        {(this.state.currentUserType === 'owner') &&
                                            <>
                                                <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-52" onClick={() => { this.onClickOpenConfirm('deleteOrg', this.deleteOrgMsg()) }}>
                                                    <img src={deleteIcon} style={{ height: '20px' }} alt='' />
                                                </button>
                                                <p className='fs-12 fw-bold text-white mt-1'>Delete</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='text-center'>
                                        <img src={instructionIcon} className='' height={'40px'} alt='' />
                                    </div>
                                    <div className='mt-3'>
                                        <ol className='ps-0'>
                                            <li className='fs-12 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                            <li className='fs-12 fw-500 text-start'>Integer sit amet odio aliquet, rhoncus augue et, semper nibh.</li>
                                            <li className='fs-12 fw-500 text-start'>Donec ac tellus vel magna iaculis pretium.</li>
                                            <li className='fs-12 fw-500 text-start'>Pellentesque id ligula rhoncus, consectetur lacus id, vulputate mi.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </section>

                <Modal show={openClanInvite} centered dialogClassName="organization-invite-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => { this.toggleModal('openClanInvite'); }} />

                            <p className="fs-20 fw-600 text-center text-white mb-0">Invite</p>
                            <p className="fs-12 fw-500 text-center text-white-50 mb-2">Step {this.state.selectInvite ? 1 : 2} of 2</p>
                            <div>
                                {this.state.selectInvite ? (
                                    <div id="invite-section">
                                        <p className="fw-600 fs-14 text-center text-white-50 mt-4 mb-1">
                                            Please select the role you would like to invite
                                        </p>
                                        <form>
                                            <Dropdown className="mb-2 select-game-dropdown">
                                                <Dropdown.Toggle className='dropdown-basic w-100 text-start ps-3'>
                                                    {capitalize(this.state.selectedRole)}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu onClick={(e) => this.onRoleChangeHandler(e)}>
                                                    {
                                                        (this.state.currentUserType === 'owner') &&
                                                        <Dropdown.Item name="owner" className='text-white'>Owner</Dropdown.Item>
                                                    }
                                                    <Dropdown.Item name="admin" className='text-white'>Admin</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" type="button" onClick={this.linkSectionContinue}>
                                                Continue
                                            </button>
                                        </form>
                                    </div>
                                ) : (
                                    <div id="link-section">
                                        <BsArrowLeftShort className="position-absolute btn-size-32 text-white" style={{ top: '10px' }} onClick={this.linkSectionContinue} />
                                        <p className="fw-600 fs-12 text-center text-white-50 mt-4">You can Invite the {`${this.state.selectedRole} `}
                                            for this organization by sharing the given link below.</p>
                                        <p className="fs-16 fw-600 text-white mb-1">Copy Link</p>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                placeholder="https://espotz.online/#Heree"
                                                value={this.state.invitationLink}
                                                style={{ paddingRight: '35px' }}
                                            // onChange={() => { }}
                                            />
                                            <MdOutlineContentCopy className="text-white me-3 position-absolute" style={{ right: '10px' }} onClick={(e) => this.copyLinkToClipBoard(e)} />
                                        </div>
                                        <div className="d-flex justify-content-center my-2">
                                            <div className="d-flex align-items-center w-50 mb-0">
                                                <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                                                <p className="fs-14 fw-normal text-white-50 mx-2 my-0">OR</p>
                                                <p className="flex-grow-1 bg-white my-auto" style={{ height: '1px' }}></p>
                                            </div>
                                        </div>
                                        <div className="text-center">

                                            <WhatsappShareButton className="px-1" url={this.state.invitationLink} quote={inviteText} title={inviteText}>
                                                <WhatsappIcon size={"2rem"} round={true} />
                                            </WhatsappShareButton>

                                            <FacebookShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                                                <FacebookIcon size={"2rem"} round={true} />
                                            </FacebookShareButton>

                                            <TelegramShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                                                <TelegramIcon size={"2rem"} round={true} />
                                            </TelegramShareButton>

                                            <TwitterShareButton className="px-1" url={this.state.invitationLink} title={inviteText}>
                                                <TwitterIcon size={"2rem"} round={true} />
                                            </TwitterShareButton>

                                            {/* <TwitterShareButton className="px-1">
                                                <img src={discord} alt='' sizes={'2rem'} round={true} />
                                            </TwitterShareButton>

                                            <TwitterShareButton className="px-1">
                                                <img src={instagram} alt='' sizes={'2rem'} round={true} />
                                            </TwitterShareButton> */}

                                            <TwitterShareButton className="px-1">
                                                <img src={loader} alt='' style={{ height: '28px' }} />
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={openBioEdit} centered dialogClassName="BioEdit-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => { this.toggleModal('openBioEdit'); this.setState({ description: this.state.orgData.bio }) }} />
                            <p className="fw-bold fs-20 text-center text-white mb-0">Description</p>

                            <form >
                                <textarea className="bio-ContentSetting text-white rounded-10 fw-500 fs-12 mt-3 w-100" rows="6" maxLength="500" value={this.state.description} onChange={(e) => this.descChange(e)} />

                                <button className="white-flat-btn d-block fw-bold py-2 px-2 m-auto mt-3" type="button" onClick={this.updateDesc}>Save</button>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openConfirm} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <ConfirmationModal confirm={this.state.confirm}
                            onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.confirmed} insteadDeactivate={{}} />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.openShowMember} centered dialogClassName="ShowMember-modal">
                    <Modal.Body>
                        <ShowMember
                            members={this.state.members}
                            onClickOpenShowMember={this.onClickOpenShowMember} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.openSingleOwner} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <SingleOwnerLeave members={this.state.orgData?.members}
                            onClick={this.singleOwnerConfirm} type="organization" />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
                    <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
                </Modal>
            </>
        )}else{
            return null;
        }
    }
}

export default OrganizationView;