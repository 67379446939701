import { Component } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiFillCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import "../../styles/Clan.css";
import CommonApis from "../../helper/common.api";
import ClanApis from "../../helper/clan.api";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { profile } from '../../utils/localstorage.utils'
import { toast } from 'material-react-toastify';
import { errorHandler } from "../../utils/common.utils";
class CreateClan extends Component {
    commonApi = new CommonApis()
    clanApi = new ClanApis()
    clanLogoUrl;
    clanCoverUrl;
    profile = profile()
    images = {
        clanLogo: null,
        clanCover: null
    }
    constructor(props) {
        super(props);
        this.state = {
            clanLogo: null,
            clanCover: null,
            isDisabled: false,
            bio : ''
        };
    }
    addImg = (event, key) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            this.setState({
                [key]: URL.createObjectURL(img),
            });
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            this.props.updateLoader(true);
            this.commonApi
                .uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.images[key] = response.data.result;
                    this.props.updateLoader(false);

                })
                .catch((err: AxiosError) => {
                    this.props.updateLoader(false);
                    console.log(err);
                }) 
        }
    }

    resetLogo = (id) => {
        let key = id
        this.setState({ [key]: null })
        this.images[key] = {}
    }

    handleCloseClick = () => {
        this.props.onClickCloseCreateClan();
    }
    onSubmit = (values) => {
        if (!this.images.clanLogo?.url) {
            this.images.clanLogo = null;
        }
        if (!this.images?.clanCover?.url) {
            this.images.clanCover = null;
        }
        const data = {
            name: values.name,
            logo: this.images?.clanLogo,
            coverImage: this.images?.clanCover,
            isActive: true,
            bio: values.bio,
            verificationStatus: "NOT_VERIFIED",
            isDeleted: false,
            members: {
                owner: []
            }
        }
        this.setState({
            isDisabled: true,
        });
        this.props.updateLoader(true);
        this.clanApi.addNewClan(data).then(
            (res: AxiosResponse) => {

                this.props.onClanCreated();
                toast.success('Clan Created Successfuly')
                this.setState({
                    clanLogo: null,
                    isDisabled: false,
                });

                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.setState({
                    clanLogo: null,
                    isDisabled: false,
                });
                this.props.updateLoader(false);
                errorHandler(err);
            }
        )
    }
    triggerInputFile = () => this.fileInput.click()

    triggerInputFile2 = () => this.fileInput2.click()

    render() {
        return (
            <div className="create-clan-page p-4">
                <div className="row">
                    <div className="col-lg-6 col-md-6 pe-md-4">
                        <div className="d-flex flex-column px-3">
                            <h5 className="text-heading fs-18 fw-bold text-white text-center mb-4">Create Clan</h5>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={this.handleCloseClick} />
                        </div>

                        {/* <p className="text-description fs-14 fw-nornal text-white text-center px-4">Build Clans, Build for the Future</p> */}
                        <div className="row">
                            {/* LOGO */}
                            <div className="col-5 col-md-5">
                                <div className="uploadWithPreview">
                                    <div className="upload-section">
                                        <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'clanLogo')} ref={fileInput => this.fileInput = fileInput} name="" className="" />
                                        {
                                            this.state.clanLogo ?
                                                <div className="text-right flex-column text-end">
                                                    <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.resetLogo('clanLogo') }} />
                                                    <div className="after-uploaded-image" onClick={this.triggerInputFile}>
                                                        <img src={this.state.clanLogo} alt="" className="" />
                                                    </div>
                                                    {/* <AiFillCloseCircle className="logo-reset-icon" onClick={this.resetLogo} /> */}
                                                </div>
                                                :
                                                <div className="before-uploaded-panel">
                                                    <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}>
                                                            <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                            <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Logo</h1>
                                                        </div>
                                                    </div>
                                                </div>

                                        }
                                        {/* <span className="d-flex position-absolute">
                                            <RiErrorWarningLine className="me-1 text-warning" />
                                            <p className="fs-12 fw-normal text-warning text-nowrap">
                                                JPEG: 512*512
                                            </p>
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            {/* COVER */}
                            <div className="col-7 col md-7">
                                <div className="uploadWithPreview">
                                    <div className="upload-section">
                                        <input type="file" accept=".png, .jpg, .jpeg" id="upload_cover_file" hidden onChange={(e) => this.addImg(e, 'clanCover')} ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="" />
                                        {
                                            this.state.clanCover ?
                                                <div className="text-right flex-column text-end">
                                                    <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.resetLogo('clanCover') }} />
                                                    <div className="after-uploaded-image" onClick={this.triggerInputFile2}>
                                                        <img src={this.state.clanCover} alt="" className="" />
                                                    </div>
                                                    {/* <AiFillCloseCircle className="logo-reset-icon" onClick={this.resetCover} /> */}
                                                </div>
                                                :
                                                <div className="before-uploaded-panel">
                                                    <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                        <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile2}  >
                                                            <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                            <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Cover</h1>
                                                        </div>
                                                    </div>
                                                </div>

                                        }
                                        {/* <span className="d-flex justify-content-center">
                                            <RiErrorWarningLine className=" me-1 text-warning" />
                                            <p className="fs-12 fw-normal text-warning text-nowrap">
                                                JPEG: 780*240
                                            </p>
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Formik
                            initialValues={{
                                name: '',
                                isOwner: '',
                                bio: '',
                                verificationStatus: 'NOT_VERIFIED',
                                isDeleted: false,
                                members: {}
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string().required('Clan Name is Required').max(50, 'Max 50 characters').matches(/(\d|\w)/, 'Only special charactor not allowed.'),
                                isOwner: Yup.string(),
                                bio: Yup.string().required('Clan Bio is Required').max(500, 'Max 500 characters'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    this.onSubmit(values)
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            <Form className="create-clan-form mt-3" autoComplete="off">
                                <div className="">
                                    <label htmlFor='name' className="fs-16 fw-600 text-white mb-1">Clan Name *</label>
                                    <Field
                                        name='name'
                                        type="text"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white height-45"
                                        placeholder="Enter clan name"
                                    />
                                    <ErrorMessage component="span" name="name" className="error-msg" />
                                </div>

                                <div className="mt-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                    <label htmlFor='bio' className="fs-16 fw-600 text-white mb-1">Clan Bio *</label>
                                        <label className="fs-12 fw-bold text-white-50">{this.state.bio?.trim().length}/500</label>
                                    </div>
                                    <Field
                                        name="bio" type="text" as="textarea"
                                        rows="4"
                                        className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white"
                                        placeholder="Write bio here..."
                                        maxLength={500}
                                        onKeyUp={(e) => this.setState({bio : e.target.value})}
                                    />
                                    <ErrorMessage component="span" name="bio" className="error-msg" />
                                </div>
                                <button type="submit" disabled={this.state.isDisabled} className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3">Create</button>
                            </Form>
                        </Formik>
                    </div>
                    <div className="col-lg-6 col-md-6 d-lg-block d-none">
                    </div>
                </div>
            </div>

        )
    }
}

export default CreateClan