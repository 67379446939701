import { Component } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Switch from "react-switch"
import validator from 'validator';
// Api
import CommonApis from "../../../helper/common.api";
import { AxiosResponse, AxiosError } from "axios";
import TournamentApis from "../../../helper/tournament.api";

// Css
import "../../../styles/Tournaments.css";
import pc from '../../../images/games/pc.png';
import mobile from '../../../images/games/mobile.png';
import nintendo from '../../../images/games/nintendo.png';
import ps from '../../../images/games/ps.png';
import xbox from '../../../images/games/xbox.png';
import location from '../../../images/games/location.png';
import proceed from '../../../images/games/proceed.png';
import facebookGaming from '../../../images/games/facebook-gaming.png';
import instagram from '../../../images/games/instagram.png';
import loco from '../../../images/games/loco.png';
import telegram from '../../../images/games/telegram.png';
import twitter from '../../../images/games/twitter.png';
import youtube from '../../../images/games/youtube.png';
import twitch from '../../../images/games/twitch.png';
import fbInline from '../../../images/games/facebook-inline.png';
import instaInline from '../../../images/games/insta-inline.png';
import locoInline from '../../../images/games/loco-inline.png';
import telegramInline from '../../../images/games/telegram-inline.png';
import twitchInline from '../../../images/games/twitch-inline.png';
import twitterInline from '../../../images/games/twitter-inline.png';
import youtubeInline from '../../../images/games/youtube-inline.png';
// Icon
import { AiOutlineCloudUpload, AiFillCloseCircle, AiFillPlusCircle, AiOutlineLock, AiOutlineMinusCircle } from "react-icons/ai";
import { IoGolf, IoArrowBackCircleOutline, IoKeypad } from "react-icons/io5";
import { IoIosCheckmarkCircle, IoIosCloseCircleOutline, IoIosPeople } from "react-icons/io";
import { RiWhatsappFill, RiDiscordFill } from "react-icons/ri";
import { FaSearch, FaGlobeAfrica, FaUserAlt } from "react-icons/fa";
import { BsTelephoneFill, BsInfoCircle, BsArrowRight, BsCalendar } from "react-icons/bs";
import { CgMenuGridR } from 'react-icons/cg';

import { NavLink } from 'react-router-dom';

// Utils
import { profile } from '../../../utils/localstorage.utils'
import { capitalize, errorHandler, is_Natural, regions, getWebData } from "../../../utils/common.utils";
import TeamApis from "../../../helper/teams.api";
import { toast } from "material-react-toastify";
import { connect } from 'react-redux';
import { setAllGames } from "../../../redux/actions/commonActions";
import { setTournamentDetails } from "../../../redux/actions/tournametActions";
import { dateFormat, formatLocalDateTime } from "../../../utils/common.utils";
import { getAllGamesDetails } from "../../../utils/Games.utils";
import { ASSETS } from "../../../utils/Strings.utils";
import { CREATE_TOURNAMENT, WEBSITE_ENUM } from "../../../utils/enums.utils";

const mapStateToProps = (props) => {
    return {
        games: props.gameData,
        tournamentData: props.tournamentData,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchData: (data, key) => {
            switch (key) {
                case 'game':
                    dispatch(setAllGames(data));
                case 'tournament':
                    dispatch(setTournamentDetails(data));
                default:
                    break;
            }

        },
    }
}
const platformsArray = [
    { url: pc, name: 'PC', value: 'PC' },
    { url: mobile, name: 'MOBILE', value: 'MOBILE' },
    { url: nintendo, name: 'SWITCH', value: 'SWITCH' },
    { url: ps, name: 'PLAYSTATION', value: 'PLAYSTATION' },
    { url: xbox, name: 'XBOX', value: 'XBOX' }
]

class CreateTournament extends Component {
    socialMediaArray = [
        { id: 'instagram', value: '', isChecked: false, url: instagram, error: '', inlineUrl: instaInline },
        { id: 'facebook', value: '', isChecked: false, url: facebookGaming, error: '', inlineUrl: fbInline },
        { id: 'twitter', value: '', isChecked: false, url: twitter, error: '', inlineUrl: twitterInline },
        { id: 'twitch', value: '', isChecked: false, url: twitch, error: '', inlineUrl: twitchInline },
        { id: 'telegram', value: '', isChecked: false, url: telegram, error: '', inlineUrl: telegramInline },
        { id: 'youtube', value: '', isChecked: false, url: youtube, error: '', inlineUrl: youtubeInline },
        { id: 'loco', value: '', isChecked: false, url: loco, error: '', inlineUrl: locoInline }
    ]
    commonApi = new CommonApis()
    tournamentApis = new TournamentApis()
    teamApi = new TeamApis()
    profile = profile()
    bannerUrl;
    gamesArray = []

    constructor(props) {
        super(props);
        this.state = {
            tournamentBanner: null,
            bannerURL: '',
            socialMediaDetails: [],
            extraDetails: this.socialMediaArray,
            openSocialMediaModal: false,
            gamesArray: [],
            buttonValues: {
                category: '',
                game: '',
                platform: [],
                participant_type: '',
                competition_type: '',
            },
            formikDetails: {},
            checked: false,
            bio: props.match.params.id ? props.tournamentData?.bio : '',
            orgSocialDetails: props?.location?.state ? props.location.state : null,
            tournamentData: props.match.params.id ? props.tournamentData : {},
            searchInput: '',
            invalidImage: null,
            regions: regions,
            type: 'BOTH',
            pType: 'BOTH',
            countryCodes: []
        };
        if (props?.match?.params?.id) {
            this.getData()
        }
        this.getCodes()
        getAllGamesDetails(this.setAllGamesDetails);
    }

    componentDidMount() {
        this.props.showOrHideNav(true);
        if (this.props.match.params.id) {
            if (!this.props?.tournamentData?._id) {
                this.getData()
            } else {
                this.initialSet(this.props.tournamentData)
            }
        }
    }

    setWebsiteData = (res) => {
        console.log('website response', res);
        this.setState({ websiteData: res[0] })
    }

    setAllGamesDetails = (response) => {
        this.setState({ gamesArray: response });
    }

    getCodes = () => {
        this.props.updateLoader(true);
        this.commonApi.getCountryCodes().then(
            (res: AxiosResponse) => {
                this.props.updateLoader(false);
                this.setState({ countryCodes: res.data.result })
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err);
            }
        );
    }

    //handle All button value changes
    handleChange = (e, key, val, type) => {
        e.preventDefault();
        const isUpdate = this.state?.orgSocialDetails?.to === 'Update' ? true : false;
        if (type === 1 && isUpdate) {
            toast.error('Edit access denied.', { autoClose: 3000 })
            return false
        }
        let data = this.state.buttonValues;
        if (key === 'game') {
            let values = this.state.buttonValues
            values.platform = []
            this.setState({
                buttonValues: values,
                regions: val.regions,
                type: val.gameType,
                pType: val.participantType
            })
        }
        if (key === 'platform') {
            let platformData = this.state.buttonValues?.platform ? this.state.buttonValues?.platform : []
            if (platformData.includes(val)) {
                let i = platformData.indexOf(val)
                platformData.splice(i, 1)
            } else {
                platformData.push(val)
            }
            data.platform = platformData;
            this.setState({ buttonValues: data })
        } else {
            data[key] = val;
            this.setState({ buttonValues: data })
        }
    }

    //upload banner image
    onBannerChange = event => {

        this.setState({
            invalidImage: null,
        });

        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            this.setState({
                bannerURL: URL.createObjectURL(img)
            });

            const formData = new FormData();
            formData.append('file', event.target.files[0])
            this.props.updateLoader(true);
            this.commonApi.uploadSingleImg(formData)
                .then((response: AxiosResponse) => {
                    this.setState({
                        tournamentBanner: response.data.result
                    });
                    this.props.updateLoader(false);
                })
                .catch((err: AxiosError) => {
                    toast.error('Failed to upload tournament image');
                    this.props.updateLoader(false);
                    console.log(err);
                });
        }
    };

    triggerInputFile = () => this.fileInput.click()

    //to append org social contacts in tournament
    appendOrgDetails = (e) => {
        this.setState({ checked: !this.state.checked })
        if (!this.state.orgSocialDetails.contact) {
            toast.error('There is no contact details in organization');
            this.setState({ checked: false });
            return false;
        }
        let data = this.state.extraDetails
        let incoming = this.state.orgSocialDetails.contact
        if (this.state.checked) {
            for (let key in incoming) {
                let index = data.map(el => { return el.id }).indexOf(key)
                if (index >= 0) {
                    data[index].value = '';
                    data[index].isChecked = false;
                }
            }
        } else {
            for (let key in incoming) {
                let index = data.map(el => { return el.id }).indexOf(key)
                if (index >= 0) {
                    data[index].value = incoming[key];
                    data[index].isChecked = true;
                }
            }
        }
        this.setState({ extraDetails: data, socialMediaDetails: data })
    }

    showFields = (e, i, type) => {
        e.preventDefault()
        const newArray = this.state.extraDetails
        let temp = this.state.extraDetails[i]
        temp.isChecked = type
        if (type === false) {
            temp.value = ''
        }
        newArray[i] = temp
        this.setState({ extraDetails: newArray })
    }

    updateDetails = (e, i) => {
        let { extraDetails } = this.state;
        extraDetails[i].value = e.target.value
        this.setState({ extraDetails });
    }

    getAddButton = () => {
        const { extraDetails } = this.state
        const booleanArray = extraDetails.map(detail => detail.isChecked)
        if (booleanArray.includes(true)) {
            return true
        }
        return false
    }

    validateDetails = () => {
        let errors = 0
        const filledFields = this.state.extraDetails
        filledFields.map((field) => {
            if (field.value !== '') {
                // const res = field.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                if (!field.value.includes(field.id)) {
                    field.error = 'Enter url of respective field';
                    errors += 1
                }
                else if ((validator.isURL(field.value)) && (field.value.includes(field.id))) {
                    field.error = ''
                }
                else {
                    errors += 1
                    field.error = 'Enter a valid url'
                }
            }
        })
        this.setState({ socialFieldArr: filledFields })
        console.log('errors:', errors)
        if (errors > 0) {
            return false
        } else {
            return true
        }
    }

    addDetails = () => {
        if (this.validateDetails()) {
            const { extraDetails } = this.state
            this.setState({
                socialMediaDetails: extraDetails,
                openSocialMediaModal: !this.state.openSocialMediaModal
            })

        }
    }

    saveFormikDetails = (e, key, type) => {
        e.preventDefault();
        const isUpdate = this.state?.orgSocialDetails?.to === 'Update' ? true : false;
        if (key === 'countryCode') {
            global.countryCode = e.target.value
        }
        if (key === 'RegStartDate' || key === 'RegEndDate' || key === 'tourStartDate' || key === 'tourEndDate') {
            let givenDate = new Date(e.target.value) // future Date
            let diff = new Date().getTime() - givenDate.getTime();
            if (diff > 0) {
                return false
            }
        }
        if (type === 1 && isUpdate) {
            toast.error('Edit access denied.', { autoClose: 3000 })
            return false
        }
        let data = this.state.formikDetails
        data[key] = e.target.value
        this.setState({ formikDetails: data })
    }
    // updateGameSearch = (e) => {
    //     e.preventDefault()
    //     const newGamesArray = this.gamesArray.filter(game => game.name.toLowerCase().includes(e.target.value.toLowerCase()))
    //     this.setState({
    //         gamesArray: newGamesArray
    //     })
    // }

    //Get data to patch for update tournament
    getData() {
        const data = {
            _id: this.props.match.params.id
        };

        this.props.updateLoader(true);

        this.tournamentApis.getTournaments(data).then(
            (res: AxiosResponse) => {
                this.props.dispatchData(res.data.result[0], 'tournament')
                this.initialSet(res.data.result[0])
                if (!this.state?.tournamentDetails) {
                    this.props.history.push('/pageNotFound')
                }
                this.props.updateLoader(false);
            }
        ).catch(
            (err: AxiosError) => {
                this.props.updateLoader(false);
                errorHandler(err, this.props.logout);
            }
        )
    }

    initialSet = (data) => {
        let result = data;
        this.setState({
            tournamentDetails: result
        });
        console.log('result ::', result);
        let formikDetails = {
            name: result.name,
            description: result?.bio,
            whatsappNo: result.contactDetails?.whatsapp,
            discord: result.contactDetails?.discord,
            maxParticipants: result?.maxParticipant,
            participantsPerTeam: result?.participantsPerTeam ? result.participantsPerTeam : '',
            region: result?.region,
            RegStartDate: result?.registrationStartTime,
            RegEndDate: result?.registrationEndTime,
            tourStartDate: result?.tournamentStartTime,
            tourEndDate: result?.tournamentEndTime,
        }
        let buttonValues = {
            category: result.visibility,
            game: result.gameDetails, //this.state.gamesArray?.filter(game => game?._id === result.gameId)?.[0],
            platform: result.platform,
            participant_type: result.participantType,
            competition_type: result.competitionType,
        }
        let extraDetails = this.state.extraDetails
        for (let key in result.contactDetails) {
            let index = extraDetails.map(el => { return el.id }).indexOf(key)
            if (index >= 0) {
                extraDetails[index].value = result.contactDetails[key];
                extraDetails[index].isChecked = true;
            }
        }
        this.setState({
            tournamentBanner: result.baner,
            formikDetails: formikDetails,
            buttonValues: buttonValues,
            extraDetails: extraDetails,
            socialMediaDetails: extraDetails,
            tournamentData: result,
            bannerURL: result.baner.url,
            regions: result.gameDetails.regions,
            type: result.gameDetails.gameType,
            pType: result.gameDetails.participantType
        })
    }

    //To create tournament
    createTournament = (values) => {
        if (!values.whatsappNo && !values.discord) {
            toast.error('Either WhatsApp number or Discord ID is required')
            return false
        }

        if (!this.state.tournamentBanner) {
            toast.error('Tournament banner is required')
            return false
        }

        for (let key in this.state.buttonValues) {
            if (key === 'platform') {
                if (!this.state.buttonValues?.platform?.length) {
                    toast.error(`${key} is required`)
                    return
                }
            } else {
                if (!this.state.buttonValues[key]) {
                    toast.error(`${capitalize(key.replace('_', ' '))} is required`)
                    return
                }
            }
        }
        const data = {
            organizationId: this.props.match.params.orgId,
            name: values.name,
            bio: values.description,
            baner: this.state.tournamentBanner ? this.state.tournamentBanner : null,
            gameId: this.state.buttonValues?.game._id,
            contactDetails: {
                whatsapp: values.whatsappNo ? { code: values.countryCode, number: values.whatsappNo } : '',
                discord: values.discord ? values.discord : ''
            },
            platform: this.state.buttonValues?.platform,
            region: this.state.formikDetails?.region,
            participantType: this.state.buttonValues.participant_type,
            maxParticipant: values.maxParticipants,
            //participantsPerTeam: values.participantsPerTeam,
            competitionType: this.state.buttonValues.competition_type,
            visibility: this.state.buttonValues.category,
            listingType: !this.props.match.params.id ? 'free' : this.tournamentData?.listingType,
            teams: !this.props.match.params.id ? [] : this.tournamentData?.teams,
            // leaderBoard: !this.props.match.params.id ? {} : this.tournamentData?.leaderBoard,
            duration: !this.props.match.params.id ? {} : this.tournamentData?.duration,
            registrationStatus: !this.props.match.params.id ? 'DRAFT' : this.tournamentData?.registrationStatus,
            isDeleted: !this.props.match.params.id ? false : this.tournamentData?.isDeleted,
            registrationStartTime: new Date(values.RegStartDate).toUTCString(),
            registrationEndTime: new Date(values.RegEndDate).toUTCString(),
            tournamentStartTime: new Date(values.tourStartDate).toUTCString(),
            tournamentEndTime: new Date(values.tourEndDate).toUTCString(),
            stages: !this.props.match.params.id ? [] : this.tournamentData?.stages,
            entryFeeAndPrizeDetails: !this.props.match.params.id ? { entryFee: false, entryFeeAmount: 0 } : this.tournamentData?.entryFeeAndPrizeDetails
        }
        if (this.state.buttonValues?.participant_type === 'TEAM') {
            data['participantsPerTeam'] = values.participantsPerTeam
        }
        for (let i = 0; i < this.state.socialMediaDetails.length; i++) {
            if (this.state.socialMediaDetails[i]?.value) {
                data.contactDetails[this.state.socialMediaDetails[i].id] = this.state.socialMediaDetails[i]?.value
            }
        }

        this.props.updateLoader(true);
        if (!this.props.match.params.id) {
            this.tournamentApis.createTournament(data).then(
                (res: AxiosResponse) => {
                    this.props.updateLoader(false);
                    toast.success('Tournament created successfully')
                    this.props.history.push({
                        pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview`,
                        state: { orgId: res.data.result.organizationId }
                    });
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        } else {
            this.tournamentApis.updateTournaments(this.props.match.params.id, data).then(
                (res: AxiosResponse) => {
                    this.props.dispatchData(res.data.result, 'tournament')
                    this.props.updateLoader(false);
                    toast.success('Tournament updated successfully')
                    this.props.history.push({
                        pathname: `/organization/${res.data.result.organizationId}/tournament/${res.data.result._id}/overview`,
                        state: {
                            orgId: res.data.result.organizationId,
                            tournamentDetails: res.data.result
                        }
                    });
                    this.props.history.push();
                }
            ).catch(
                (err: AxiosError) => {
                    this.props.updateLoader(false);
                    errorHandler(err, this.props.logout);
                }
            )
        }
    }

    // showToolTip = (id, place = "top", type = "warning", text) => {
    //     return (
    //         <ReactTooltip id={id} place={place} effect="float" type={type}>
    //             {text}
    //         </ReactTooltip>
    //     )
    // }

    maxParticipantVal = (val) => {
        let error;
        let number = parseFloat(val)
        if (number === 1) {
            error = 'Max participants should be more than one';
        }
        if (!is_Natural(number)) {
            error = `Invalid input value`;
        } else if (number && (number & (number - 1)) !== 0) {
            error = `Max participants should be in form of 2,4,8,16...`;
        }
        return error;
    }
    render() {
        const { gamesArray, invalidImage, searchInput, type, place, effect, pType } = this.state;
        const isUpdate = this.state?.orgSocialDetails?.to === 'Update' ? true : false;
        const regStart = this.state.formikDetails?.RegStartDate ? new Date(this.state.formikDetails?.RegStartDate) : new Date();
        const currentTime = new Date()
        const isPastRegStart = currentTime > regStart;
        let isFFA = (type === 'FFA' || (type === 'BOTH'));
        let isDUEL = (type === 'DUEL' || (type === 'BOTH'));
        let isSINGLE = (pType === 'SINGLE' || (pType === 'BOTH'));
        let isTEAM = (pType === 'TEAM' || (pType === 'BOTH'));
        let code = '+91';
        let mobile;
        if (this.state.orgSocialDetails?.to === 'Create') {
            if (!this.state.isChecked) {
                code = this.state.orgSocialDetails?.contact?.whatsapp?.code;
                mobile = this.state.orgSocialDetails?.contact?.whatsapp?.number
            } else {
                mobile = ''
            }
        } else {
            code = this.state.formikDetails?.whatsappNo?.code;
            mobile = this.state.formikDetails?.whatsappNo?.number;
        }
        const requiredObject = getWebData();
        return (
            <>
                {/* Two component handle from create tournament
            1. create tournament page
            2. Tournament basic details page*/}

                {this.state.gamesArray?.length ?
                    <section className="body-section create-tournament-page">
                        <div className="container p-3 mt-lg-3">
                            {
                                this.props?.match?.params?.id &&
                                <div>
                                    <IoArrowBackCircleOutline onClick={() => this.props.history.goBack()} className="back-btn mt-2" />
                                    <NavLink className="text-white" to={{
                                        pathname: `/organization/${this.props.match.params.orgId}/tournament/${this.props.match.params.id}/overview`,
                                        state: {
                                            isConfig: true
                                        }
                                    }}>
                                        <CgMenuGridR className='fs-20 float-end' />
                                    </NavLink>
                                </div>
                            }

                            <div className="d-flex flex-row justify-content-center mb-3">
                                <IoGolf className="btn-size-20 me-2 mb-1" />
                                <h1 className="fs-20 fw-bold" style={{ marginBottom: 0 }}>{this.state?.orgSocialDetails?.to ? this.state?.orgSocialDetails?.to : 'Create'} Tournament</h1>
                                {/* <h1 className="clan-heading" style={{ marginBottom: 0 }}>Basic Details</h1> */}
                            </div>

                            <Formik
                                enableReinitialize
                                initialValues={{
                                    name: this.state.formikDetails?.name ? this.state.formikDetails.name : '',
                                    description: this.state.formikDetails?.description ? this.state.formikDetails.description : '',
                                    whatsappNo: mobile,
                                    discord: profile()?.discordProfile?.discordUsername ? profile()?.discordProfile?.discordUsername : "",
                                    maxParticipants: this.state.formikDetails?.maxParticipants ? this.state.formikDetails.maxParticipants : '',
                                    region: this.state.formikDetails?.region ? this.state.formikDetails.region : '',
                                    RegStartDate: this.state.formikDetails?.RegStartDate ? this.state.formikDetails.RegStartDate : '',
                                    RegEndDate: this.state.formikDetails?.RegEndDate ? this.state.formikDetails.RegEndDate : '',
                                    tourStartDate: this.state.formikDetails?.tourStartDate ? this.state.formikDetails.tourStartDate : '',
                                    tourEndDate: this.state.formikDetails?.tourEndDate ? this.state.formikDetails.tourEndDate : '',
                                    countryCode: this.state.formikDetails?.countryCode ? this.state.formikDetails.countryCode : code,
                                    participantsPerTeam: this.state.formikDetails?.participantsPerTeam ? this.state.formikDetails.participantsPerTeam : (this.state.buttonValues.game ? this.state.buttonValues?.game?.participantsAllowedPerTeam[0] : '')
                                }}

                                validationSchema={Yup.object({
                                    name: Yup.string()
                                    .trim()
                                    .required("Tournament name is required")
                                    .matches(/^[a-zA-Z0-9 _-]+$/, 'Only alphanumeric characters, spaces, hyphens (-), and underscores (_) are allowed.')
                                    .max(25, 'Max 25 characters'),                                
                                    description: Yup.string().required("Tournament description is required").max(250, 'Max 250 characters'),
                                    whatsappNo: Yup.string().matches(/^[0-9]*$/, 'only numerics allowed').min(9, "min 9 digits required").max(12, "max 12 digits allowed"),
                                    // discord: Yup.string().matches(/^.{3,32}#[0-9]{4}$/, 'Invalid Username'),
                                    discord: Yup.string(),
                                    RegStartDate: this.state?.orgSocialDetails?.to ? Yup.date().required("Registration start time required") : Yup.date().required("Registration start time required").min(new Date(), 'Start time cannot be in past'),
                                    RegEndDate: Yup.date().required("Registration end time required").min(Yup.ref('RegStartDate'), "End time can't be before start date"),
                                    tourStartDate: Yup.date().required("Tournament start time is required").min(Yup.ref('RegEndDate'), "Can't start before registration end time"),
                                    tourEndDate: Yup.date().required("Tournament end time is required").min(Yup.ref('tourStartDate'), "Can't end before tournament start time"),
                                    maxParticipants: Yup.number().required('Enter Max participants allowed'),
                                    participantsPerTeam: Yup.number()
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        this.createTournament(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                                onChange={(values) => {
                                    this.getMicroWeb(values)
                                }
                                }
                            >
                                <Form className="row create-tournament-form m-1" autoComplete="off">
                                    <div className="col-lg-6 col-12 p-lg-4">
                                        <div className="col-lg-4 col-md-4 col-7 m-auto">
                                            <div className="uploadWithPreview">
                                                <div className="upload-section">
                                                    <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden ref={(fileInput) => (this.fileInput = fileInput)} onChange={this.onBannerChange} name="" className="image-input" />
                                                    {
                                                        this.state.bannerURL !== '' ?
                                                            <div className="text-right flex-column text-end">
                                                                <AiFillCloseCircle className="btn-size-20 position-absolute text-white" style={{ marginLeft: '-10px', marginTop: '-10px' }} onClick={() => { this.setState({ tournamentBanner: null, bannerURL: '' }) }} />
                                                                <div className="after-uploaded-image" onClick={this.triggerInputFile} >
                                                                    <img src={this.state.bannerURL} alt="" />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="before-uploaded-panel">
                                                                <div className="upload-banner w-100 d-flex align-items-center justify-content-center">
                                                                    <div className="btn-size-30 d-flex flex-column justify-content-center" onClick={this.triggerInputFile}  >
                                                                        <AiOutlineCloudUpload className="btn-size-25 text-white-50 mx-auto" />
                                                                        <h1 className="fs-16 fw-600 text-white-50" htmlFor="exampleInputText">Banner</h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                    {/* {invalidImage && (<p className="error-msg mwc-image-validation"> {invalidImage} </p>)} */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <label htmlFor="name" className="fs-14 fw-600 text-white mb-1"> Name</label>
                                            <Field
                                                name="name" type="text"
                                                disabled={isUpdate}
                                                className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                placeholder="Enter Tournament Name"
                                                onKeyUp={(e) => { this.saveFormikDetails(e, 'name', 1) }}
                                            />
                                            <ErrorMessage component="span" className="error-msg" name="name" />
                                        </div>
                                        {/* description */}
                                        <div className="mt-2">
                                            <div className="d-flex justify-content-between">
                                                <label htmlFor="description" className="fs-14 fw-600 text-white mb-1">Description</label>
                                                <label className="fs-12 fw-bold text-white-50">{this.state.formikDetails?.description?.length}/250</label>
                                            </div>
                                            <Field
                                                name="description" type="text" as="textarea"
                                                maxLength={250} rows="4"
                                                disabled={isUpdate}
                                                className="purple-field d-flex align-items-center w-100 border-0 p-2 text-white fs-14"
                                                placeholder="Enter Description"
                                                onKeyUp={(e) => { this.saveFormikDetails(e, 'description', 1); this.setState({ bio: e.target.value }) }}
                                            />
                                            <ErrorMessage component="span" className="error-msg" name="description" />
                                        </div>

                                        <div className="mt-2">
                                            <label htmlFor="RegStartDate" className="fs-14 fw-600 text-white mb-1">Registration Start Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="RegStartDate" type="datetime-local"
                                                    minDate={new Date()}
                                                    disabled={isPastRegStart && this.props.match.params?.id}
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Registration Start Date-Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'RegStartDate', 1) }}
                                                    value={
                                                        this.state.formikDetails?.RegStartDate
                                                            ? formatLocalDateTime(this.state.formikDetails.RegStartDate)
                                                            : null // Fallback to current date
                                                    }
                                                />
                                                {/* <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                                                    {dateFormat(this.state.formikDetails?.RegStartDate)}
                                                    <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                </p> */}
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="RegStartDate" />
                                        </div>
                                        <div className="mt-2 startDate" >
                                            <label htmlFor="RegEndDate" className="fs-14 fw-600 text-white mb-1">Registration End Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="RegEndDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Registration End Date Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'RegEndDate', 2) }}
                                                    value={
                                                        this.state.formikDetails?.RegEndDate
                                                            ? formatLocalDateTime(this.state.formikDetails.RegEndDate)
                                                            : null
                                                    }
                                                />
                                                {/* <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ width: '88%', marginTop: '-45px', marginBottom: '0px' }}>
                                                    {dateFormat(this.state.formikDetails?.RegEndDate)}
                                                    <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                </p> */}
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="RegEndDate" />
                                        </div>
                                        <div className="mt-2 tourStart">
                                            <label htmlFor="tourStartDate" className="fs-14 fw-600 text-white mb-1">Tournament Start Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="tourStartDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Tournament Start Date & Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'tourStartDate', 2) }}
                                                    value={
                                                        this.state.formikDetails?.tourStartDate
                                                            ? formatLocalDateTime(this.state.formikDetails.tourStartDate)
                                                            : null
                                                    }
                                                />
                                                {/* <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                                                    {dateFormat(this.state.formikDetails?.tourStartDate)}
                                                    <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                </p> */}
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="tourStartDate" />
                                        </div>
                                        <div className="mt-2 tourEnd">
                                            <label htmlFor="tourEndtDate" className="fs-14 fw-600 text-white mb-1">Tournament End Date & Time</label>
                                            <div className='d-flex'>
                                                <Field
                                                    name="tourEndDate"
                                                    type="datetime-local"
                                                    className="purple-field w-100 border-0 px-3 fs-14 height-45 text-white"
                                                    placeholder="Tournament Start Date & Time"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'tourEndDate', 2) }}
                                                    value={
                                                        this.state.formikDetails?.tourEndDate
                                                            ? formatLocalDateTime(this.state.formikDetails.tourEndDate)
                                                            : null
                                                    }
                                                />
                                                {/* <p className="purple-field d-flex align-items-center border-0 ps-3 text-white fs-14 fw-500 height-45 w-100" style={{ marginTop: '-45px', marginBottom: '0px' }}>
                                                    {dateFormat(this.state.formikDetails?.tourEndDate)}
                                                    <BsCalendar className='text-white position-relative ms-auto me-4' style={{ marginTop: '-5px' }} />
                                                </p> */}
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="tourEndDate" />
                                        </div>

                                        <div className="my-3">
                                            <div className="">
                                                <label htmlFor="custom-btn" className="fs-14 fw-600 text-white mb-1"> Category </label>
                                                <a href="#" title={requiredObject.INFORMATION_TEXT?.CREATE_TOURNAMENT.CATEGORY} className="tooltip-info"><BsInfoCircle className="btn-size-12 ms-1" /></a>

                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${this.state.buttonValues?.category === 'PUBLIC' ? 'epz-active-field' : ''}`} onClick={(e) => this.handleChange(e, 'category', 'PUBLIC', 2)}>
                                                        <FaGlobeAfrica className="btn-size-20 text-white" />
                                                        <h6 className="fs-12 fw-500 text-white mb-0">Public</h6>
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 border-0 ${this.state.buttonValues?.category === 'PRIVATE' ? 'epz-active-field' : ''}`} onClick={(e) => this.handleChange(e, 'category', 'PRIVATE', 2)}>
                                                        <AiOutlineLock className="btn-size-20 text-white" />
                                                        <h6 className="fs-12 fw-500 text-white mb-0">Invitational</h6>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <h1 className="fs-14 fw-600 text-white mb-2">Games</h1>
                                            <div className="" style={{ display: this.state?.orgSocialDetails?.to === 'Update' ? 'none' : 'block' }}>
                                                <input
                                                    type='search'
                                                    placeholder="Search Games..."
                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-3 text-white fs-14 height-45"
                                                    onChange={e => this.setState({ searchInput: e.target.value })}
                                                />
                                                <FaSearch className="btn-size-20 me-3 position-relative float-end" style={{ marginTop: '-30px' }} />
                                            </div>
                                            <div className="row my-3">
                                                {(this.state.gamesArray.length) && (this.state.gamesArray.filter(games => games.name.toLowerCase().includes(this.state.searchInput.toLowerCase())).length) ?
                                                    (this.state.gamesArray.filter(games => games.name.toLowerCase().includes(this.state.searchInput.toLowerCase())))?.map((game, i) => {
                                                        if (game.isGameEnabled) {
                                                            return (
                                                                <div className="col-6 col-md-4" key={i}>
                                                                    <div className={`tournament-game-card d-flex flex-column justify-content-center w-100 ${this.state.buttonValues?.game?._id === game._id ? 'epz-active-field' : ''}`} onClick={(e) => { this.handleChange(e, 'game', game, 1) }} style={{ backgroundImage: `url(${game?.logo?.url || "https://placehold.co/400"})` }}>
                                                                        {(this.state.buttonValues?.game?._id === game._id) ? <IoIosCheckmarkCircle className="m-auto" style={{ height: '30px', width: '30px' }} /> : null}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    :
                                                    <div className="text-center">
                                                        <img className="no-tour-found" src={ASSETS.EMPTY_COLLECTION} alt={""} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12 p-lg-4">

                                        <div className="my-3 mt-lg-0">
                                            <div className="d-flex" style={{ alignItems: 'center' }}>
                                                <hr className="border-1 flex-grow-1" style={{ height: '2px' }} />
                                                <div className="d-flex mx-3" style={{ alignItems: 'center' }}>
                                                    <BsTelephoneFill className="btn-size-20 me-2" />
                                                    <p className="fs-16 fw-bold text-white-50 mb-0">Contact Details</p>
                                                </div>
                                                <hr className="border-1 flex-grow-1" style={{ height: '2px' }} />
                                            </div>
                                            {
                                                (!this.props.match.params.id) &&
                                                <div className="catalina-blue my-3 height-45 d-flex align-items-center">
                                                    <p className="fs-12 fw-600 mb-0 flex-grow-1 ps-3">Use Organizaton's Details</p>
                                                    <Switch onChange={(e) => { this.appendOrgDetails(e) }} checked={this.state.checked} checkedIcon={false} uncheckedIcon={false} className="me-2" />
                                                </div>
                                            }

                                        </div>
                                        <div className="my-3">
                                            <div className="">
                                                <RiWhatsappFill className="btn-size-20 text-white-50 ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                <Field name="countryCode" as='select' className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45" onChange={(e) => { this.saveFormikDetails(e, 'countryCode', 2) }}>
                                                    {this.state.countryCodes.map((el, i) => {
                                                        return <option value={el.code} key={i}>{`${el.code} ${el.name}`}</option>
                                                    })}
                                                </Field>
                                                <div className="purple-field d-flex align-items-center w-100 border-0 text-white fs-14 height-45 mt-2">
                                                    <IoKeypad className="btn-size-20 text-white-50 ms-3" />
                                                    <Field
                                                        name="whatsappNo" type='text'
                                                        className="text-white border-0 ms-3"
                                                        placeholder="Whatsapp number"
                                                        onKeyUp={(e) => { this.saveFormikDetails(e, 'whatsappNo', 2) }}
                                                        style={{ backgroundColor: 'transparent', flexGrow: 1, outline: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="whatsappNo" />
                                        </div>
                                        <div className="my-3">
                                            <div className="">
                                                <RiDiscordFill className="btn-size-20 text-white-50 ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                <Field
                                                    name="discord"
                                                    type="text"
                                                    disabled={true}
                                                    className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45"
                                                    placeholder="mike#1234"
                                                // onKeyUp={(e) => { this.saveFormikDetails(e, 'discord', 2) }}
                                                />
                                            </div>
                                            <ErrorMessage component="span" className="error-msg" name="discord" />
                                        </div>

                                        {this.state.socialMediaDetails?.map((details, i) => {
                                            if (details.value !== '' && details.error === '') {
                                                return (
                                                    <div className="my-2" key={i}>
                                                        <img src={details.inlineUrl} className="btn-size-20 ms-3 position-absolute" style={{ marginTop: '12px' }} alt="" />
                                                        <input
                                                            type='text'
                                                            placeholder="SocialMedia.com/help"
                                                            className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 height-45"
                                                            value={details.value}
                                                            disabled
                                                        />
                                                        <AiOutlineMinusCircle className="btn-size-20 me-3 text-white flex-shrink-0 float-end" style={{ marginTop: '-30px' }} onClick={e => { this.showFields(e, i, false) }} />
                                                    </div>
                                                )
                                            }

                                        })}

                                        <div className="black-flat-btn d-flex flex-row align-items-center justify-content-center mx-auto height-45 rounded-10 mt-2" onClick={(e) => { e.preventDefault(); this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }}>
                                            <button className="btn">
                                                <AiFillPlusCircle className="btn-size-25 text-white" />
                                            </button>
                                        </div>

                                        <div className="my-3">
                                            <label htmlFor="platform" className="fs-14 fw-600 text-white mb-1 mb-1">Platforms</label>
                                            <div className="row justify-content-evenly">
                                                {platformsArray.map((platform, i) => {
                                                    const isPlatformPresent = this.state.buttonValues?.game?.platforms?.includes(platform.value)
                                                    if (isPlatformPresent) {
                                                        return (
                                                            <div className="col-6" key={i}>
                                                                <button className={`pink-skyblue-gradient-btn w-100 height-55 d-flex flex-column align-items-center justify-content-center border-0 mb-3 py-lg-5 ${this.state.buttonValues.platform.includes(platform.name) ? 'epz-active-field' : ''}`} onClick={(e) => this.handleChange(e, 'platform', platform.value, 1)}>
                                                                    <img src={platform.url} alt={platform.name} />
                                                                    <p className="text-white fs-12 fw-600 mb-0">{platform.name}</p>
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {!this.state.buttonValues?.game && <p className="text-white fs-12 fw-600">Please select a game to display platforms</p>}
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <label htmlFor="platform" className="fs-14 fw-600 text-white mb-1 mb-1">Region</label>
                                            <div className="">
                                                <img src={location} alt="" className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                <Field
                                                    name="region"
                                                    as="select"
                                                    disabled={isUpdate}
                                                    className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45"
                                                    onChange={(e) => { this.saveFormikDetails(e, 'region', 1) }}
                                                >
                                                    {this.state.regions?.map((region, i) => {
                                                        return <option key={i} value={region}>{region}</option>
                                                    })}
                                                </Field>
                                                <ErrorMessage component="span" className="error-msg" name="region" />
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <div className="">
                                                <label htmlFor="custom-btn" className="fs-14 fw-600 text-white mb-1 mb-1 w-100" style={{ maxWidth: 'fit-content' }}>Participants Type</label>
                                                <a href="#" title={requiredObject.INFORMATION_TEXT?.CREATE_TOURNAMENT.PARTICIPANTS_TYPE} className="tooltip-info"><BsInfoCircle className="btn-size-12 ms-1 text-white" /></a>
                                            </div>

                                            <div className="row">
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-6" style={{ display: isTEAM ? 'block' : 'none' }}>
                                                        <button className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 ${this.state.buttonValues?.participant_type === 'TEAM' ? 'epz-active-field' : ''}`} value='TEAM' onClick={(e) => this.handleChange(e, 'participant_type', 'TEAM', 1)}>
                                                            <div className="d-flex mx-auto" style={{ alignItems: 'center' }}>
                                                                <IoIosPeople className="btn-size-20 text-white" />
                                                                <p className="text-white fs-12 fw-600 mb-0 mx-2 my-0">Vs</p>
                                                                <IoIosPeople className="btn-size-20 text-white" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div className="col-6" style={{ display: isSINGLE ? 'block' : 'none' }}>
                                                        <button className={`fs-14 fw-600 height-55 rounded-10 d-flex flex-column align-items-center  justify-content-center outline-0 border-0 text-white mb-3 pink-skyblue-gradient-btn w-100 ${this.state.buttonValues?.participant_type === 'SINGLE' ? 'epz-active-field' : ''}`} value='SINGLE' onClick={(e) => this.handleChange(e, 'participant_type', 'SINGLE', 1)}>
                                                            <div className="d-flex mx-auto" style={{ alignItems: 'center' }}>
                                                                <FaUserAlt className="btn-size-20 text-white" />
                                                                <p className="text-white fs-12 fw-600 mb-0 mx-2 my-0">Vs</p>
                                                                <FaUserAlt className="btn-size-20 text-white" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-3">
                                                <label className="fs-14 fw-600 text-white">Max {this.state.buttonValues?.participant_type === 'TEAM' ? 'Teams' : 'Participants'}</label>
                                                <div className="">
                                                    <FaUserAlt className="btn-size-20 text-white-50 mx-2 ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                    <Field
                                                        name="maxParticipants"
                                                        type="number"
                                                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45"
                                                        placeholder="Enter Max Participants"
                                                        validate={this.maxParticipantVal}
                                                        onKeyUp={(e) => { this.saveFormikDetails(e, 'maxParticipants', 2) }}
                                                        min={2} step={1}
                                                        onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                                <ErrorMessage component="span" className="error-msg" name="maxParticipants" />
                                            </div>
                                            <div className="my-3" style={{ display: this.state.buttonValues?.participant_type === 'TEAM' ? 'block' : 'none' }}>
                                                <label className="fs-14 fw-600 text-white">Participant per Team</label>
                                                <div className="">
                                                    <FaUserAlt className="btn-size-20 text-white-50 mx-2 ms-3 position-absolute" style={{ marginTop: '12px' }} />
                                                    <Field
                                                        name='participantsPerTeam'
                                                        as="select"
                                                        disabled={isUpdate}
                                                        className="sponsor-dropdown purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 height-45"
                                                        onChange={(e) => { this.saveFormikDetails(e, 'participantsPerTeam', 1) }}
                                                    >
                                                        {(this.state.buttonValues.game && this.state.buttonValues.game.participantsAllowedPerTeam.length) ?
                                                            this.state.buttonValues.game.participantsAllowedPerTeam.map((el, i) => {
                                                                return <option key={i} value={el}>{el}</option>
                                                            })
                                                            :
                                                            <option>{'No Data Found'}</option>
                                                        }
                                                    </Field>
                                                </div>
                                                <ErrorMessage component="span" className="error-msg" name="participantsPerTeam" />
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <div className="">
                                                <label htmlFor="custom-btn" className="fs-14 fw-600 text-white mb-1 w-100" style={{ maxWidth: 'fit-content' }}>Competition Type</label>
                                                <a href="#" title={requiredObject.INFORMATION_TEXT?.CREATE_TOURNAMENT.COMPETITION_TYPE} className="tooltip-info">
                                                    <BsInfoCircle className="btn-size-12 ms-1" />
                                                </a>
                                            </div>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-6" style={{ display: isFFA ? 'block' : 'none' }}>
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 d-flex align-items-center justify-content-center border-0 ${this.state.buttonValues?.competition_type === 'FFA' ? 'epz-active-field' : ''}`} onClick={(e) => this.handleChange(e, 'competition_type', 'FFA', 1)}>
                                                        <p className="text-white fs-16 fw-600 mb-0">FFA</p>
                                                    </button>
                                                </div>
                                                <div className="col-6" style={{ display: isDUEL ? 'block' : 'none' }}>
                                                    <button className={`pink-skyblue-gradient-btn w-100 height-55 d-flex align-items-center justify-content-center border-0 ${this.state.buttonValues?.competition_type === 'DUEL' ? 'epz-active-field' : ''}`} onClick={(e) => this.handleChange(e, 'competition_type', 'DUEL', 1)}>
                                                        <p className="text-white fs-16 fw-600 mb-0">Duel</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center mt-5">
                                        <button className="round-btn rounded-circle d-flex align-items-center justify-content-center border-0 mx-auto btn-size-40" type="submit">
                                            <BsArrowRight className='black-rock fs-24' />
                                        </button>
                                    </div>

                                </Form>
                            </Formik>

                        </div>
                    </section>
                    :
                    null
                }

                <Modal show={this.state.openSocialMediaModal} centered dialogClassName="social-media-modal">
                    <Modal.Body>
                        <div className="p-3">
                            <p className="fs-18 fw-bold text-white text-center mb-2">Social Media Details</p>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '25px', top: '20px' }} onClick={() => { this.setState({ openSocialMediaModal: !this.state.openSocialMediaModal }) }} />

                            <div className="my-3">
                                {this.state.extraDetails.map((details, i) => {
                                    if (details.isChecked) {
                                        return (
                                            <div className="my-1" key={i}>
                                                <div className="purple-field d-flex align-items-center w-100 border-0 ">
                                                    <img src={details.inlineUrl} className="btn-size-20 mx-2" alt="" />
                                                    <input
                                                        type='text'
                                                        placeholder="SocialMedia.com/help"
                                                        className="text-white fs-14 height-45 border-0 flex-grow-1"
                                                        value={details.value}
                                                        onChange={e => this.updateDetails(e, i)}
                                                        style={{ backgroundColor: 'transparent', outline: 'none' }}
                                                    />
                                                    <AiOutlineMinusCircle className="btn-size-20 mx-2 text-white-50" onClick={e => { this.showFields(e, i, false) }} />
                                                </div>
                                                <p className="error-msg">{details.error}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="d-flex flex-wrap justify-content-center my-3">
                                {this.state.extraDetails.map((media, i) => {
                                    return (
                                        <button key={i} className="purple-field border-0 rounded-circle d-flex justify-content-center align-items-center btn-size-52 mb-1 mx-1" onClick={e => { this.showFields(e, i, true) }}>
                                            <img src={media.url} alt={media.id} />
                                        </button>
                                    )
                                })}
                            </div>
                            {this.getAddButton() ? <button className="white-flat-btn d-block fw-bold py-2 px-4 m-auto mt-3 mb-2" onClick={this.addDetails}>Add</button> : ''}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default (connect(mapStateToProps, mapDispatchToProps))(CreateTournament)
