import { Component } from 'react';
// Style
import '../../styles/profile.css'
// Assets
import { AiOutlineMail, AiFillCamera } from "react-icons/ai";
import { VscLock } from "react-icons/vsc";
import { FiEye, FiEyeOff, FiUser, FiEdit } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import discordIcon from '../../images/icon/discord.png';
import { BsFillPersonFill, BsCheckCircleFill, BsPersonLinesFill, BsDiscord } from 'react-icons/bs';
import participantAvatar from '../../images/participantAvtar.jpg';
// Services
import CommonApis from "../../helper/common.api";
import ClanApis from "../../helper/clan.api";
import AuthApis from '../../helper/auth.api';
// utils
import { errorHandler, defaultPreviewImage, capitalize, getAvatarForLetter } from '../../utils/common.utils';
import { profile } from '../../utils/localstorage.utils'
import { toast } from 'material-react-toastify';
import packageJson from '../../../package.json'
import { AxiosResponse, AxiosError } from "axios";
import { getToken } from "../../utils/localstorage.utils"
import validator from 'validator';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactTooltip from 'react-tooltip';
import { requiredField, emailValidation } from '../../utils/Validations';
// Modals
import { Modal } from 'react-bootstrap';
import Logout from '../popup/Logout';
import AlertBox from '../popup/AlertBox';
// Commons
import ImagePreview from '../common/ImagePreview';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: this.props.authUser.user?.name,
      email: this.props.authUser.user?.email,
      username: this.props.authUser.user?.username,
      profileLogo: this.props.authUser.user?.profilePicture?.url,
      nameReadOnly: true,
      emailReadOnly: true,
      usernameReadonly: true,
      user: this.props.authUser.user,
      emailErr: false,
      emailErrMsg: '',
      openLogout: false,
      validName: true,
      nameErr: '',
      validUsername: true,
      userNameErr: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showNewPassword: false,
      updatePassword: false,
      invalidImage: [],
      isDisabled: false,
      previewImageDetails: {
        display: false,
        src: defaultPreviewImage
      },
      discordProfile: props.discordProfile
    };

    this.commonApi = new CommonApis()
    this.clanApi = new ClanApis()
    this.authApi = new AuthApis();
    this.profile = profile();

  }


  componentDidMount() {
    this.props.showOrHideNav(true);
    this.updateForm(this.props.authUser);
    this.setState({
      user: this.props.authUser.user,
      name: this.props.authUser.user.name,
      email: this.props.authUser.user.email,
      username: this.props.authUser.user.username,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.discordProfile !== this.props.discordProfile) {
      this.setState({ discordProfile: this.props.discordProfile })
    }
  }

  updateForm = (authUser) => {
    this.setState({
      user: authUser.user,
      name: authUser.user?.name,
      email: authUser.user?.email,
      username: authUser.user?.username
    });
  }

  refreshMyProfileSection = () => {
    this.setState({
      name: this.props.authUser.user?.name,
      email: this.props.authUser.user.email,
      username: this.props.authUser.user.username,
      profileLogo: this.props.authUser.user.profilePicture.url,
      nameReadOnly: true,
      emailReadOnly: true,
      usernameReadonly: true,
      user: this.props.authUser.user,
      openLogout: false,
      updatePassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  }

  setPrevState = (key) => {
    this.setState(prevState => ({ [key]: !prevState.key }))
  }

  handleEditToggle = (key) => {
    this.setState(prevState => ({ [key]: !prevState[key] }))
  }


  updateState = (key, e) => {
    this.setState({ [key]: e.target.value });
    if (key === 'email') {
      this.validateEmail();
    }
  }



  onImageChange = event => {
    this.setState({
      invalidImage: null,
    });

    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        profileLogo: URL.createObjectURL(img)
      });

      if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.warning('Please select a valid image type.');
        return false;
      }
      this.props.updateLoader(true);
      const formData = new FormData();
      formData.append('file', event.target.files[0])
      this.commonApi.uploadSingleImg(formData).then(
        (response: AxiosResponse) => {
          this.profileLogoUrl = response.data.result;
          this.updateProfile(event, "profilePicture");
          this.props.updateLoader(false);
        }).catch(
          (err: AxiosError) => {
            this.props.updateLoader(false);
            errorHandler(err);
          });
    }
  };
  triggerInputFile = () => this.fileInput.click()

  //Name Validation
  nameValidation = () => {
    let result = requiredField(this.state.name)
    this.setState({ validName: !result["error"], nameErr: result["message"] })
  }
  //Username Validation
  usernameValidation = () => {
    let result = requiredField(this.state.username)
    this.setState({ validUsername: !result["error"], userNameErr: result["message"] })
  }
  //email validation
  validateEmail = () => {
    let result = emailValidation(this.state.email)
    this.setState({ emailErr: !result["error"], emailErrMsg: result["message"] })
  };

  updateProfile = (e, param) => {
    e.preventDefault();
    var data;
    if (param === "profilePicture") {
      data = {
        [param]: this.profileLogoUrl
      }
    } else if (param === 'username') {
      data = {
        [param]: this.state.username
      }
    } else {
      data = {
        [param]: this.state[param]
      }
    }
    if (param === "name") {
      this.nameValidation()
    }

    if (param === "username") {
      this.usernameValidation()
    }

    if (param === "email") {
      this.validateEmail()
    }

    if ((param === "email" && this.state.emailErr) || !this.state.validName || !this.state.validUsername) {
      return 0;
    }
    this.props.updateLoader(true);
    this.authApi.updateProfile(data)
      .then((response: AxiosResponse) => {

        this.refreshMyProfileSection();
        localStorage.setItem('login', JSON.stringify({
          login: true,
          token: getToken(),
          authUser: {
            user: response.data.result
          }
        }));
        if (param === "profilePicture") {
          this.setState({
            profileLogo: this.profileLogoUrl.url
          });
        }

        this.props.storeCollector();
        this.updateForm({ user: response.data.result });
        this.props.updateLoader(false);
        toast.success(capitalize(param) + " updated successfully !!");
      }).catch((reason: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(reason);
      });

  }

  validateEmail = () => {
    const email = this.state.email
    if (email === "") {
      this.setState({

        emailErr: true,
        emailErrMsg: "*email required",
      });
    } else {
      if (validator.isEmail(email)) {
        this.setState({
          emailErr: false,
          emailErrMsg: "Valid Email",
        });
      } else {
        this.setState({
          emailErr: true,
          emailErrMsg: "Invalid Email",
        });
      }
    }
  };

  updatePassword = (e, param) => {
    e.preventDefault();
    // this.refreshMyProfileSection();
    this.setState({
      updatePassword: param
    })
  }

  exit = () => {
    this.refreshMyProfileSection();
    this.props.logout()
  }

  showToolTip = (id, place = "top", type = "warning", text) => {
    return (
      <ReactTooltip id={id} place={place} effect="solid" type={type}>
        {text}
      </ReactTooltip>
    )
  }

  onSubmit = (values) => {
    // e.preventDefault();
    // this.setState({
    var data = {
      oldPassword: values.oldPassword.trim(),
      newPassword: values.newPassword.trim()
    }

    this.props.updateLoader(true);
    this.authApi.changePassword(data)
      .then((response: AxiosResponse) => {

        this.refreshMyProfileSection();
        this.props.updateLoader(false);
        toast.success("Password updated successfully");
      }).catch((reason: AxiosError) => {
        this.props.updateLoader(false);
        errorHandler(reason);
      });
  }
  connectDiscord = () => {
    const userId = profile()?.id;

    if (!userId) {
        alert("User ID is required to connect to Discord.");
        return;
    }

    const url = `${process.env.REACT_APP_API_URL}/public/api/v1/auth/discord?type=CONNECT&userId=${encodeURIComponent(userId)}`;
    console.log("Redirecting to Discord OAuth URL:", url);

    window.open(url, "_self");
};

  previewImage = (src, param) => {
    this.setState({
      previewImageDetails: {
        display: param,
        src: (param ? src : defaultPreviewImage)
      }
    });
  }

  getDefaultProfile = (name) => {
    return `https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-${this.state.name.charAt(0).toLowerCase()}.png`;
  }

  render() {
    const { showPassword, showNewPassword, invalidImage } = this.state
    const version = packageJson.version;
    const defaultImage = getAvatarForLetter(this.state.name.charAt(0).toLowerCase());

    return (
      <section className="body-section profile-section">
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 d-flex flex-column px-5 mx-0">
              <h1 className="fs-20 fs-lg-24 text-white text-center fw-600"><BsFillPersonFill className='mb-1 show-icon' />  Profile</h1>
              <div className="image-cam-container">
                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={this.onImageChange} ref={fileInput => this.fileInput = fileInput} name="" className="profile-picker" />
                {
                  this.state.profileLogo ?
                    <div className="profile-logo">
                      <img
                        src={this.state.profileLogo ? this.state.profileLogo : participantAvatar}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = defaultImage;
                        }}
                        onClick={() => this.previewImage((this.state.profileLogo), true)}
                        alt="" className='rounded-circle' />
                    </div>
                    :
                    <div className="panel">
                      <div className="button_outer">
                        <div className="btn_upload" onClick={this.triggerInputFile}>
                        </div>
                      </div>
                    </div>
                }
                <AiFillCamera data-tip data-for="camera" className="camera-icon text-white rounded-circle" id="upload_file" onClick={this.triggerInputFile} />
                {this.showToolTip('camera', 'right', 'warning', 'Edit Image')}
                {invalidImage && <p className="error-msg img-error">{invalidImage}</p>}
              </div>
              <p className="fs-14 fs-lg-18 text-white-50 text-center fw-bold">Change Profile Picture</p>

              {this.state.updatePassword ?

                <Formik
                  innerRef={this.formikRef}
                  enableReinitialize
                  initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                  }}
                  validationSchema={Yup.object({
                    oldPassword: Yup.string().required("Old Password is required"),

                    newPassword: Yup.string().required("New Password is required").matches(
                      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                      "Password must contain at least 6 characters, one uppercase, one number and one special case character"),

                    confirmPassword: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      this.onSubmit(values)
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  <Form className="row" autoComplete="off">

                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                      <h1 htmlFor="oldPassword" className="fs-16 fs-lg-20 fw-600 text-white">Old Password</h1>
                      <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      <Field
                        name="oldPassword"
                        type="password"
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                        placeholder="Enter Old Password"
                      />
                      <ErrorMessage component="span" className="error-msg" name="oldPassword" />
                    </div>


                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                      <h1 htmlFor="newPassword" className="fs-16 fs-lg-20 fw-600 text-white" >New Password</h1>
                      <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      {showNewPassword && (
                        <FiEye className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('showNewPassword') }} />
                      )}
                      {!showNewPassword && (
                        <FiEyeOff className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('showNewPassword') }} />
                      )}
                      <Field
                        name="newPassword"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                        placeholder="Enter New Password"
                      />
                      <ErrorMessage component="span" className="error-msg" name="newPassword" />
                    </div>

                    <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                      <h1 htmlFor="confirmPassword" className="fs-16 fs-lg-20 fw-600 text-white">Confirm Password</h1>
                      <VscLock className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      {showPassword && (
                        <FiEye className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('showPassword') }} />
                      )}
                      {!showPassword && (
                        <FiEyeOff className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('showPassword') }} />
                      )}
                      <Field
                        name="confirmPassword"
                        type={this.state.showPassword ? 'text' : 'password'}
                        className="purple-field d-flex align-items-center w-100 border-0 ps-5 text-white fs-14 fs-lg-18 height-45"
                        placeholder="Enter Confirm Password"
                      />
                      <ErrorMessage component="span" className="error-msg" name="confirmPassword" />
                    </div>

                    <div className="d-flex my-4">
                      <button className="white-flat-btn fw-bold py-2 px-4 m-auto me-1" type="button" onClick={(e) => { this.updatePassword(e, false) }}> Back </button>
                      <button type="submit" className="white-flat-btn fw-bold py-2 px-4 m-auto ms-1"> Update </button>
                    </div>
                  </Form>
                </Formik>

                // <form className="new-password-form">
                //   {this.renderOldPassword()}
                //   {this.renderNewPassword()}
                //   {this.renderRepeatPassword()}

                //   <div className="confirm-btn mx-auto">
                //     <button  className={`btn clan-btn ${buttonClass}`} type="submit"
                //     disabled={(requiredPassword || invalidPassword || passwordMatch) ? false: true} onClick={(e)=>this.changePassword(e)}>
                //       Update
                //     </button>
                //     <button className="btn clan-btn" type="submit" onClick={this.updatePassword}>
                //       Close
                //     </button>
                //   </div>
                // </form>

                :

                <div>
                  <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                    <label htmlFor="name" className="fs-16 fs-lg-20 fw-600 text-white">Name*</label>
                    <div className="">
                      <FiUser className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      {this.state.nameReadOnly ?
                        <>
                          <FiEdit data-tip data-for="editName" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => this.handleEditToggle('nameReadOnly')} />
                          {this.showToolTip('editName', 'top', 'warning', 'Edit Name')}
                        </>
                        :
                        <>
                          <BsCheckCircleFill data-tip data-for="saveName" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "name")} />
                          {this.showToolTip('saveName', 'top', 'success', 'Save Name')}
                        </>
                      }
                      <input
                        type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45"
                        id="name"
                        ref="name"
                        value={this.state.name}
                        readOnly={this.state.nameReadOnly}
                        onChange={(e) => this.updateState('name', e)}
                        onBlur={this.nameValidation}
                      />

                    </div>
                    <p className="error-msg">{this.state.nameErr}</p>
                  </div>

                  <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                    <label htmlFor="email" className="fs-16 fs-lg-20 fw-600 text-white">Email Address*</label>
                    <div className="">
                      <AiOutlineMail className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      {this.props.authUser.user.isEmailVerified ?
                        <>
                          <MdVerified data-tip data-for="verified" className="btn-size-20 text-white ms-3 field-eye-icon text-success float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} />
                          {this.showToolTip('verified', 'top', 'success', 'Verified Account')}
                        </>
                        :
                        <>
                          {this.state.emailReadOnly ?
                            // <MdEdit className="eye-icon" onClick={this.handleEmail} />
                            <>
                              <FiEdit data-tip data-for="editMail" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('emailReadOnly') }} />
                              {this.showToolTip('editMail', 'top', 'warning', 'Edit Email')}
                            </>
                            :
                            // <MdDone className="eye-icon" onClick={(e) => this.updateProfile(e, "email")} />
                            <>
                              <BsCheckCircleFill data-tip data-for="saveEmail" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "email")} />
                              {this.showToolTip('saveEmail', 'top', 'success', 'Save Email')}
                            </>
                          }
                        </>
                      }
                      <input
                        type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white pe-5 fs-14 fs-lg-18 height-45"
                        id="email"
                        ref="email"
                        value={this.state.email}
                        readOnly={this.state.emailReadOnly}
                        onChange={(e) => this.updateState('email', e)}
                      />
                    </div>
                    {this.state.emailErr && <p className="error-msg">{this.state.emailErrMsg}</p>}
                  </div>

                  <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                    <label htmlFor="username" className="fs-16 fs-lg-20 fw-600 text-white">Espotz Username*</label>
                    <div className="">
                      <BsPersonLinesFill className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                      {this.state.usernameReadonly ?
                        // <MdEdit className="eye-icon" onClick={this.handleUsername} />
                        <>
                          <FiEdit data-tip data-for="editUserame" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={() => { this.handleEditToggle('usernameReadonly') }} />
                          {this.showToolTip('editUserame', 'top', 'warning', 'Edit Username')}
                        </>
                        :
                        // <MdDone className="eye-icon" onClick={(e) => this.updateProfile(e, "username")} />
                        <>
                          <BsCheckCircleFill data-tip data-for="saveUsername" className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} onClick={(e) => this.updateProfile(e, "username")} />
                          {this.showToolTip('saveUsername', 'top', 'success', 'Save Username')}
                        </>
                      }
                      <input
                        type="text"
                        className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45"
                        id="username"
                        ref="username"
                        value={this.state.username}
                        readOnly={this.state.usernameReadonly}
                        onChange={(e) => this.updateState('username', e)}
                        onBlur={this.usernameValidation}
                      />
                    </div>
                    <p className="error-msg">{this.state.userNameErr}</p>
                  </div>
                  {
                    this.state.discordProfile ?
                      <div className="col-lg-8 offset-lg-2 col-12 mt-2">
                        <label htmlFor="username" className="fs-16 fs-lg-20 fw-600 text-white">Discord Username*</label>
                        <div className="">
                          <BsDiscord className="btn-size-20 text-white ms-3 position-absolute" style={{ marginTop: '12px' }} />
                          <MdVerified className="btn-size-20 text-white ms-3 field-eye-icon float-end me-3" style={{ marginTop: '10px', marginBottom: '-30px' }} width={'15px'} />
                          <input
                            type="text"
                            className="purple-field d-flex align-items-center w-100 border-0 px-5 text-white fs-14 fs-lg-18 height-45"
                            id="discordUsername"
                            ref="username"
                            value={this.state.discordProfile}
                            disabled={true}
                          />
                        </div>
                        <p className="error-msg">{this.state.userNameErr}</p>
                      </div>
                      :
                      <div className='col-lg-4 offset-lg-4 col-md-4 offset-md-4 col-12 mt-2'>
                        <button className="white-flat-btn w-100 fw-bold py-2 m-auto mt-2" onClick={this.connectDiscord}>
                          <img src={discordIcon} className='mx-2' alt="discord icon" width={'15px'} />
                          Connect Discord
                        </button>
                      </div>
                  }
                  <div className='col-lg-2 offset-lg-5 col-md-4 offset-md-4 col-12 mt-2'>
                    <button className="white-flat-btn w-100 fw-bold py-2 m-auto my-4" onClick={(e) => this.updatePassword(e, true)}>Change Password</button>
                  </div>
                </div>
              }

              <span className="text-center text-white-50 fs-12 fw-500 d-lg-none d-block">v{version}</span>
              {/* <button className="home-outlined-button" onClick={this.openLogout}>Sign Out</button> */}

            </div>
          </div >
        </div >

        <Modal show={this.state.openLogout}>
          <Modal.Body>
            <Logout
              openLogout={this.state.openLogout}
              closeLogoutModal={this.refreshMyProfileSection}
              logout={this.exit}
            />
          </Modal.Body>
        </Modal>

        <Modal show={(this.state.updatePassword) && (!this.state.user.typeOfLogin.includes("Manual"))} centered>
          <Modal.Body>
            <AlertBox
              title={"WARNING"}
              message={`You have signed up using a social platform hence password cannot be changed, please go through the "Forgot Password" process to link a password to your account.`}
              closeAlert={this.refreshMyProfileSection}
            />
          </Modal.Body>
        </Modal>

        <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
          <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
        </Modal>

      </section >
    );
  }
}

export default Profile;
